import React, { useState, useEffect } from "react";
import BackButton from "./BackButton";
import CartIcon from "./Carticon";
import { useNavigate } from "react-router-dom";

const About = () => {
  const navigate = useNavigate();

  // Initialize cart state from localStorage
  const [cart, setCart] = useState(() => {
    const savedCart = localStorage.getItem("currentCart");
    return savedCart ? JSON.parse(savedCart) : [];
  });

  // Cart related states
  const [showCartModal, setShowCartModal] = useState(false);
  const [touching, setTouching] = useState(false);
  const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });
  const [cartPosition, setCartPosition] = useState(() => {
    const saved = localStorage.getItem("cartPosition");
    return saved ? JSON.parse(saved) : { x: window.innerWidth - 100, y: 20 };
  });

  const [touchPosition, setTouchPosition] = useState({
    x: window.innerWidth - 80,
    y: window.innerHeight / 2,
  });

  const handleDragStart = (e) => {
    const rect = e.target.getBoundingClientRect();
    const offsetX = e.clientX - rect.left;
    const offsetY = e.clientY - rect.top;
    e.dataTransfer.setData("text/plain", `${offsetX},${offsetY}`);
  };

  const handleDrag = (e) => {
    if (e.clientX && e.clientY) {
      const [offsetX, offsetY] = e.dataTransfer
        .getData("text/plain")
        .split(",");
      const x = Math.min(
        Math.max(0, e.clientX - offsetX),
        window.innerWidth - 100
      );
      const y = Math.min(
        Math.max(0, e.clientY - offsetY),
        window.innerHeight - 100
      );
      setCartPosition({ x, y });
      localStorage.setItem("cartPosition", JSON.stringify({ x, y }));
    }
  };

  const handleMobileTouchStart = (e) => {
    const touch = e.touches[0];
    setTouching(true);
    setDragOffset({
      x: touch.clientX - touchPosition.x,
      y: touch.clientY - touchPosition.y,
    });
  };

  const handleMobileTouchMove = (e) => {
    if (touching) {
      const touch = e.touches[0];
      const newX = Math.min(
        Math.max(0, touch.clientX - dragOffset.x),
        window.innerWidth - 80
      );
      const newY = Math.min(
        Math.max(0, touch.clientY - dragOffset.y),
        window.innerHeight - 80
      );
      setTouchPosition({ x: newX, y: newY });
    }
  };

  const handleMobileTouchEnd = () => setTouching(false);

  const handleUpdateCart = (menuId, quantity) => {
    quantity <= 0
      ? setCart((prevCart) =>
          prevCart.filter((item) => item.menu_id !== menuId)
        )
      : setCart((prevCart) =>
          prevCart.map((item) =>
            item.menu_id === menuId ? { ...item, quantity } : item
          )
        );
  };

  const handleAddToCart = (item) => {
    setCart((prevCart) => {
      const existingItem = prevCart.find((i) => i.menu_id === item.menu_id);
      return existingItem
        ? prevCart.map((i) =>
            i.menu_id === item.menu_id ? { ...i, quantity: i.quantity + 1 } : i
          )
        : [...prevCart, { ...item, quantity: 1 }];
    });
  };

  const calculateTotal = () =>
    cart
      .reduce((total, item) => total + item.price * item.quantity, 0)
      .toFixed(2);

  const handleCheckout = () => {
    navigate("/cart", {
      state: { cart, restaurant_id: localStorage.getItem("restaurant_id") },
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="max-w-6xl mx-auto px-4 py-12 relative">
      <BackButton />
      <div className="max-w-6xl mx-auto px-4 py-12">
        <h1 className="text-3xl font-bold text-gray-800 text-center mb-12">
          About DineFaster
        </h1>

        <div className="space-y-12">
          {/* Mission Section */}
          <div className="bg-gradient-to-br from-blue-50 to-white p-8 rounded-xl shadow-lg text-center">
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">
              Our Mission
            </h2>
            <p className="text-gray-600 text-lg leading-relaxed">
              To revolutionize the dining experience by providing innovative
              digital solutions that enhance restaurant operations and customer
              satisfaction.
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            <div className="bg-white p-6 rounded-xl shadow-lg transform hover:scale-105 transition-transform">
              <div className="w-12 h-12 bg-blue-100 rounded-lg flex items-center justify-center mb-4">
                <svg
                  className="w-6 h-6 text-blue-600"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2"
                  />
                </svg>
              </div>
              <h3 className="text-xl font-semibold text-gray-800 mb-2">
                Smart Ordering
              </h3>
              <p className="text-gray-600">
                Streamlined digital ordering system for faster service
              </p>
            </div>

            <div className="bg-white p-6 rounded-xl shadow-lg transform hover:scale-105 transition-transform">
              <div className="w-12 h-12 bg-blue-100 rounded-lg flex items-center justify-center mb-4">
                <svg
                  className="w-6 h-6 text-blue-600"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M3 10h18M3 14h18m-9-4v8m-7 0h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z"
                  />
                </svg>
              </div>
              <h3 className="text-xl font-semibold text-gray-800 mb-2">
                Table Management
              </h3>
              <p className="text-gray-600">
                Efficient table allocation and management system
              </p>
            </div>

            <div className="bg-white p-6 rounded-xl shadow-lg transform hover:scale-105 transition-transform">
              <div className="w-12 h-12 bg-blue-100 rounded-lg flex items-center justify-center mb-4">
                <svg
                  className="w-6 h-6 text-blue-600"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M13 10V3L4 14h7v7l9-11h-7z"
                  />
                </svg>
              </div>
              <h3 className="text-xl font-semibold text-gray-800 mb-2">
                Real-time Updates
              </h3>
              <p className="text-gray-600">
                Instant order tracking and status updates
              </p>
            </div>

            <div className="bg-white p-6 rounded-xl shadow-lg transform hover:scale-105 transition-transform">
              <div className="w-12 h-12 bg-blue-100 rounded-lg flex items-center justify-center mb-4">
                <svg
                  className="w-6 h-6 text-blue-600"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z"
                  />
                </svg>
              </div>
              <h3 className="text-xl font-semibold text-gray-800 mb-2">
                Analytics
              </h3>
              <p className="text-gray-600">
                Comprehensive reporting and business insights
              </p>
            </div>
          </div>
        </div>
      </div>
      {cart.length > 0 && (
        <CartIcon
          cart={cart}
          showCartModal={showCartModal}
          setShowCartModal={setShowCartModal}
          touching={touching}
          touchPosition={touchPosition}
          handleMobileTouchStart={handleMobileTouchStart}
          handleMobileTouchMove={handleMobileTouchMove}
          handleMobileTouchEnd={handleMobileTouchEnd}
          cartPosition={cartPosition}
          handleDragStart={handleDragStart}
          handleDrag={handleDrag}
          handleUpdateCart={handleUpdateCart}
          handleAddToCart={handleAddToCart}
          calculateTotal={calculateTotal}
          handleCheckout={handleCheckout}
        />
      )}
    </div>
  );
};

export default About;
