import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const AuthGuard = ({component}) => {
    const [status, setStatus] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {
        const userId = sessionStorage.getItem('user_id');
        if (!userId) {
            navigate('/')
        }
    }, []);

    return <React.Fragment>{component}</React.Fragment>
}

export default AuthGuard;