import React from 'react';

function NotificationCart({ message, status }) {
  return (
    <div className="notification-cart">
        <div
        className="notification-item"
        style={{
            backgroundColor: status === "error" ? '#f8d7da' : '#d4edda',
            color: status === "error" ? '#721c24' : '#155724',
            border: status === "error" ? '1px solid #f5c6cb' : '1px solid #c3e6cb',
            padding: '8px',
            margin: '5px 0',
            borderRadius: '4px'
        }}
        >
        {message}
        </div>
    </div>
  );
}

export default NotificationCart;
