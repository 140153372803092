import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import config from '../config';
import './ResetPassword.css';

const ResetPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const token = new URLSearchParams(location.search).get('token');
  const type = new URLSearchParams(location.search).get('type');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage('');
    if (password !== confirmPassword) {
      setErrorMessage('Passwords do not match');
      return;
    }
    try {
      console.log("token", token);
      
      if (type === 'restaurant') {
        const response = await axios.post(`${config.API_BASE_URL}/auth/reset_password`, { token, new_password: password });
        setSuccessMessage('Password reset successfully. Redirecting to login...');
        setTimeout(() => navigate('/login'), 3000);
      }else{
        const response = await axios.post(`${config.API_BASE_URL}/customer/reset_password`, { token, new_password: password });
        setSuccessMessage('Password reset successfully. Redirecting to login...');
        setTimeout(() => navigate('/customer_details'), 3000);
      }
    } catch (error) {
      if (error.response) {
        setErrorMessage(error.response.data.message || 'An error occurred. Please try again.');
      } else {
        setErrorMessage('An error occurred. Please try again.');
      }
    }
  };

  return (
    <div className="reset-password">
      <h2>Reset Password</h2>
      {errorMessage && <div className="error-message">{errorMessage}</div>}
      {successMessage && <div className="success-message">{successMessage}</div>}
      <form onSubmit={handleSubmit}>
        <label>New Password:</label>
        <input 
          type="password" 
          value={password} 
          onChange={(e) => setPassword(e.target.value)} 
          required 
        />
        <label>Confirm New Password:</label>
        <input 
          type="password" 
          value={confirmPassword} 
          onChange={(e) => setConfirmPassword(e.target.value)} 
          required 
        />
        <button type="submit">Reset Password</button>
      </form>
    </div>
  );
};

export default ResetPassword;
