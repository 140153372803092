import React, { useState, useEffect } from "react";
import axios from "axios";
import config from "../config";
import NotificationCart from "./NotificationCart";

const CustomerLogin = ({ onLoginSuccess, onClose }) => {
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [resetEmail, setResetEmail] = useState("");
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const [formMode, setFormMode] = useState("login");
  const [userData, setUserData] = useState({
    email: "",
    password: "",
    customer_name: "",
    phone_number: "",
  });
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationStatus, setNotificationStatus] = useState("");
  const [isNotification, setIsNotification] = useState(false);

  useEffect(() => {
    const storedUserData = localStorage.getItem("customerData");
    if (storedUserData && storedUserData !== "undefined") {
      const parsedData = JSON.parse(storedUserData);
      setUserData(parsedData);
      onLoginSuccess(parsedData);
    }
  }, [onLoginSuccess]);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${config.API_BASE_URL}/customer/login`,
        userData
      );
      setUserData(response.data);
      localStorage.setItem("customerData", JSON.stringify(response.data));
      setNotificationMessage("Login successful");
      setNotificationStatus("success");
      setIsNotification(true);
      setTimeout(() => {
        setIsNotification(false);
        onLoginSuccess(response.data);
      }, 2000);
    } catch (error) {
      setNotificationMessage("Invalid email or password");
      setNotificationStatus("error");
      setIsNotification(true);
      setTimeout(() => {
        setIsNotification(false);
      }, 9000);
    }
  };

  const handleClose = () => {
    if (typeof onClose === "function") {
      onClose();
    }
  };

  const handleSignup = async (e) => {
    e.preventDefault();
    if (!termsAccepted) {
      setNotificationMessage("Please accept the Terms and Conditions");
      setNotificationStatus("error");
      setIsNotification(true);
      setTimeout(() => {
        setIsNotification(false);
      }, 9000);
      return;
    }
    if (userData.password !== passwordConfirmation) {
      setNotificationMessage("Passwords do not match");
      setNotificationStatus("error");
      setIsNotification(true);
      setTimeout(() => {
        setIsNotification(false);
      }, 9000);
      return;
    }
    try {
      const response = await axios.post(
        `${config.API_BASE_URL}/customer/create`,
        userData
      );
      setUserData(response.data);
      localStorage.setItem("customerData", JSON.stringify(response.data));
      setNotificationMessage("Account created successfully");
      setNotificationStatus("success");
      setIsNotification(true);
      setTimeout(() => {
        setIsNotification(false);
        onLoginSuccess(response.data);
      }, 9000);
    } catch (error) {
      setNotificationMessage("Error creating account. Please try again.");
      setNotificationStatus("error");
      setIsNotification(true);
      setTimeout(() => {
        setIsNotification(false);
      }, 9000);
    }
  };

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${config.API_BASE_URL}/customer/forgot-password`,
        {
          email: resetEmail,
        }
      );
      setNotificationMessage(
        response.data.message || "Password reset link sent to your email"
      );
      setNotificationStatus("success");
      setIsNotification(true);
      setTimeout(() => {
        setIsNotification(false);
        setFormMode("login");
      }, 9000);
    } catch (error) {
      setNotificationMessage("Error resetting password. Please try again.");
      setNotificationStatus("error");
      setIsNotification(true);
      setTimeout(() => {
        setIsNotification(false);
      }, 9000);
    }
  };

  return (
      <div className="w-full">
        <div className="max-w-md w-[100%] sm:w-full mx-auto bg-white rounded-lg shadow-lg relative p-4 sm:p-8">
          <button
            className="absolute top-4 right-4 text-2xl text-gray-600 hover:text-[#4e7ab4] transition-colors duration-300"
            onClick={handleClose}
          >
            ×
          </button>
        <h1 className="text-3xl font-bold text-center text-gray-800 mb-8">
          Customer Login
        </h1>

        {isNotification && (
          <NotificationCart
            message={notificationMessage}
            status={notificationStatus}
          />
        )}

        {formMode === "forgotPassword" && (
          <div className="space-y-6">
            <h2 className="text-2xl font-semibold text-gray-700">
              Forgot Password
            </h2>
            <form onSubmit={handleForgotPassword} className="space-y-4">
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">
                  Email:
                </label>
                <input
                  type="email"
                  value={resetEmail}
                  onChange={(e) => setResetEmail(e.target.value)}
                  required
                  className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-[#4e7ab4] focus:border-transparent"
                />
              </div>
              <div className="flex flex-col space-y-3">
                <button
                  type="submit"
                  className="w-full py-2 px-4 bg-[#abbfff] hover:bg-[#4e7ab4] text-white font-semibold rounded-md transition-colors duration-300"
                >
                  Send Reset Link
                </button>
                <button
                  type="button"
                  onClick={() => setFormMode("login")}
                  className="text-[#4e7ab4] hover:text-[#23354d] text-sm font-medium transition-colors"
                >
                  Back to Login
                </button>
              </div>
            </form>
          </div>
        )}

        {formMode === "login" && (
          <div className="space-y-6">
            <h2 className="text-2xl font-semibold text-gray-700">Log In</h2>
            <form onSubmit={handleLogin} className="space-y-4">
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">
                  Email:
                </label>
                <input
                  type="email"
                  value={userData.email}
                  onChange={(e) =>
                    setUserData({ ...userData, email: e.target.value })
                  }
                  required
                  className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-[#4e7ab4] focus:border-transparent"
                />
              </div>
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">
                  Password:
                </label>
                <input
                  type="password"
                  value={userData.password}
                  onChange={(e) =>
                    setUserData({ ...userData, password: e.target.value })
                  }
                  required
                  className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-[#4e7ab4] focus:border-transparent"
                />
              </div>
              <div className="flex flex-col space-y-3">
                <button
                  type="submit"
                  className="w-full py-2 px-4 bg-[#abbfff] hover:bg-[#4e7ab4] text-white font-semibold rounded-md transition-colors duration-300"
                >
                  Log In
                </button>
                <button
                  type="button"
                  onClick={() => setFormMode("signup")}
                  className="w-full py-2 px-4 bg-[#4e7ab4] hover:bg-[#23354d] text-white font-semibold rounded-md transition-colors duration-300"
                >
                  Create Account
                </button>
                <button
                  onClick={() => setFormMode("forgotPassword")}
                  className="text-[#4e7ab4] hover:text-[#23354d] text-sm font-medium transition-colors"
                >
                  Forgot Password?
                </button>
              </div>
            </form>
          </div>
        )}

        {formMode === "signup" && (
          <div className="space-y-6">
            <h2 className="text-2xl font-semibold text-gray-700">
              Create Account
            </h2>
            <form onSubmit={handleSignup} className="space-y-4">
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">
                  Name:
                </label>
                <input
                  type="text"
                  value={userData.customer_name}
                  onChange={(e) =>
                    setUserData({ ...userData, customer_name: e.target.value })
                  }
                  required
                  className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-[#4e7ab4] focus:border-transparent"
                />
              </div>
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">
                  Email:
                </label>
                <input
                  type="email"
                  value={userData.email}
                  onChange={(e) =>
                    setUserData({ ...userData, email: e.target.value })
                  }
                  required
                  className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-[#4e7ab4] focus:border-transparent"
                />
              </div>
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">
                  Phone Number:
                </label>
                <input
                  type="tel"
                  value={userData.phone_number}
                  onChange={(e) =>
                    setUserData({ ...userData, phone_number: e.target.value })
                  }
                  pattern="[0-9]{10}"
                  maxLength="10"
                  required
                  className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-[#4e7ab4] focus:border-transparent"
                />
              </div>
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">
                  Password:
                </label>
                <input
                  type="password"
                  value={userData.password}
                  onChange={(e) =>
                    setUserData({ ...userData, password: e.target.value })
                  }
                  required
                  className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-[#4e7ab4] focus:border-transparent"
                />
              </div>
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">
                  Confirm Password:
                </label>
                <input
                  type="password"
                  value={passwordConfirmation}
                  onChange={(e) => setPasswordConfirmation(e.target.value)}
                  required
                  className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-[#4e7ab4] focus:border-transparent"
                />
              </div>
              <div className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  checked={termsAccepted}
                  onChange={(e) => setTermsAccepted(e.target.checked)}
                  required
                  className="w-4 h-4 text-[#4e7ab4] border-gray-300 rounded focus:ring-[#4e7ab4]"
                />
                <label className="text-sm text-gray-600">
                  I agree to the{" "}
                  <a href="#" className="text-[#4e7ab4] hover:underline">
                    Terms and Conditions
                  </a>
                </label>
              </div>
              <div className="flex flex-col space-y-3">
                <button
                  type="submit"
                  className="w-full py-2 px-4 bg-[#abbfff] hover:bg-[#4e7ab4] text-white font-semibold rounded-md transition-colors duration-300"
                >
                  Sign Up
                </button>
                <button
                  type="button"
                  onClick={() => setFormMode("login")}
                  className="text-[#4e7ab4] hover:text-[#23354d] text-sm font-medium transition-colors"
                >
                  Already have an account? Log In
                </button>
              </div>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomerLogin;
