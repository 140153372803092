import React, { useState, useEffect } from "react";
import BackButton from "./BackButton";
import CartIcon from "./Carticon";
import { useNavigate } from "react-router-dom";

const TermsAndConditions = () => {
  const navigate = useNavigate();

  // Initialize cart state from localStorage
  const [cart, setCart] = useState(() => {
    const savedCart = localStorage.getItem("currentCart");
    return savedCart ? JSON.parse(savedCart) : [];
  });

  // Cart related states
  const [showCartModal, setShowCartModal] = useState(false);
  const [touching, setTouching] = useState(false);
  const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });
  const [cartPosition, setCartPosition] = useState(() => {
    const saved = localStorage.getItem("cartPosition");
    return saved ? JSON.parse(saved) : { x: window.innerWidth - 100, y: 20 };
  });

  const [touchPosition, setTouchPosition] = useState({
    x: window.innerWidth - 80,
    y: window.innerHeight / 2,
  });

  const handleDragStart = (e) => {
    const rect = e.target.getBoundingClientRect();
    const offsetX = e.clientX - rect.left;
    const offsetY = e.clientY - rect.top;
    e.dataTransfer.setData("text/plain", `${offsetX},${offsetY}`);
  };

  const handleDrag = (e) => {
    if (e.clientX && e.clientY) {
      const [offsetX, offsetY] = e.dataTransfer
        .getData("text/plain")
        .split(",");
      const x = Math.min(
        Math.max(0, e.clientX - offsetX),
        window.innerWidth - 100
      );
      const y = Math.min(
        Math.max(0, e.clientY - offsetY),
        window.innerHeight - 100
      );
      setCartPosition({ x, y });
      localStorage.setItem("cartPosition", JSON.stringify({ x, y }));
    }
  };

  const handleMobileTouchStart = (e) => {
    const touch = e.touches[0];
    setTouching(true);
    setDragOffset({
      x: touch.clientX - touchPosition.x,
      y: touch.clientY - touchPosition.y,
    });
  };

  const handleMobileTouchMove = (e) => {
    if (touching) {
      const touch = e.touches[0];
      const newX = Math.min(
        Math.max(0, touch.clientX - dragOffset.x),
        window.innerWidth - 80
      );
      const newY = Math.min(
        Math.max(0, touch.clientY - dragOffset.y),
        window.innerHeight - 80
      );
      setTouchPosition({ x: newX, y: newY });
    }
  };

  const handleMobileTouchEnd = () => setTouching(false);

  const handleUpdateCart = (menuId, quantity) => {
    quantity <= 0
      ? setCart((prevCart) =>
          prevCart.filter((item) => item.menu_id !== menuId)
        )
      : setCart((prevCart) =>
          prevCart.map((item) =>
            item.menu_id === menuId ? { ...item, quantity } : item
          )
        );
  };

  const handleAddToCart = (item) => {
    setCart((prevCart) => {
      const existingItem = prevCart.find((i) => i.menu_id === item.menu_id);
      return existingItem
        ? prevCart.map((i) =>
            i.menu_id === item.menu_id ? { ...i, quantity: i.quantity + 1 } : i
          )
        : [...prevCart, { ...item, quantity: 1 }];
    });
  };

  const calculateTotal = () =>
    cart
      .reduce((total, item) => total + item.price * item.quantity, 0)
      .toFixed(2);

  const handleCheckout = () => {
    navigate("/cart", {
      state: { cart, restaurant_id: localStorage.getItem("restaurant_id") },
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="max-w-6xl mx-auto px-4 py-12 relative">
      <BackButton />
      <div className="max-w-4xl mx-auto px-4 py-12">
        <h1 className="text-3xl font-bold text-gray-800 text-center mb-8">
          Terms and Conditions
        </h1>

        <div className="space-y-8">
          <section className="bg-white rounded-xl shadow-lg p-8 hover:shadow-xl transition-shadow">
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">
              1. Service Usage
            </h2>
            <p className="text-gray-600 leading-relaxed">
              By accessing and using DineFaster's services, you agree to comply
              with these terms and conditions. Our platform provides restaurant
              management and ordering solutions designed to enhance your dining
              experience.
            </p>
          </section>

          <section className="bg-white rounded-xl shadow-lg p-8 hover:shadow-xl transition-shadow">
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">
              2. User Responsibilities
            </h2>
            <div className="space-y-4 text-gray-600 leading-relaxed">
              <p>Users are responsible for:</p>
              <ul className="list-disc pl-6 space-y-2">
                <li>
                  Maintaining the confidentiality of their account credentials
                </li>
                <li>Providing accurate and up-to-date information</li>
                <li>Complying with all applicable laws and regulations</li>
                <li>
                  Using the service in a manner that does not disrupt other
                  users
                </li>
              </ul>
            </div>
          </section>

          <section className="bg-white rounded-xl shadow-lg p-8 hover:shadow-xl transition-shadow">
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">
              3. Privacy Policy
            </h2>
            <div className="space-y-4 text-gray-600 leading-relaxed">
              <p>Our privacy policy outlines how we:</p>
              <ul className="list-disc pl-6 space-y-2">
                <li>Collect and use your personal information</li>
                <li>Protect your data and maintain confidentiality</li>
                <li>Handle data retention and deletion requests</li>
                <li>Comply with data protection regulations</li>
              </ul>
            </div>
          </section>

          <section className="bg-white rounded-xl shadow-lg p-8 hover:shadow-xl transition-shadow">
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">
              4. Payment Terms
            </h2>
            <div className="space-y-4 text-gray-600 leading-relaxed">
              <p>All payments processed through our platform are:</p>
              <ul className="list-disc pl-6 space-y-2">
                <li>Secure and encrypted</li>
                <li>Subject to verification and approval</li>
                <li>Processed in accordance with local regulations</li>
                <li>Refundable according to our refund policy</li>
              </ul>
            </div>
          </section>

          <section className="bg-white rounded-xl shadow-lg p-8 hover:shadow-xl transition-shadow">
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">
              5. Service Modifications
            </h2>
            <p className="text-gray-600 leading-relaxed">
              DineFaster reserves the right to modify, suspend, or discontinue
              any aspect of our services at any time. Users will be notified of
              significant changes that affect their use of the platform.
            </p>
          </section>
        </div>

        <div className="mt-12 text-center">
          <p className="text-sm text-gray-500">
            Last updated: {new Date().toLocaleDateString()}
          </p>
        </div>
      </div>
      {cart.length > 0 && (
        <CartIcon
          cart={cart}
          showCartModal={showCartModal}
          setShowCartModal={setShowCartModal}
          touching={touching}
          touchPosition={touchPosition}
          handleMobileTouchStart={handleMobileTouchStart}
          handleMobileTouchMove={handleMobileTouchMove}
          handleMobileTouchEnd={handleMobileTouchEnd}
          cartPosition={cartPosition}
          handleDragStart={handleDragStart}
          handleDrag={handleDrag}
          handleUpdateCart={handleUpdateCart}
          handleAddToCart={handleAddToCart}
          calculateTotal={calculateTotal}
          handleCheckout={handleCheckout}
        />
      )}
    </div>
  );
};

export default TermsAndConditions;
