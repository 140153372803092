import React, { useState } from 'react';
import axios from 'axios';
import './PlaceOrder.css';
import config from "../config";

const PlaceOrder = () => {
  const [formData, setFormData] = useState({
    restaurant_id: sessionStorage.getItem('restaurant_id'),
    user_id: sessionStorage.getItem('user_id'),
    customer_name: '',
    phone_number: '',
    total_amount: '',
    other_order_details: '',
    items: [{ menu_id: '', quantity: '' }]
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleItemChange = (index, e) => {
    const { name, value } = e.target;
    const items = [...formData.items];
    items[index][name] = value;
    setFormData({ ...formData, items });
  };

  const handleAddItem = () => {
    setFormData({ ...formData, items: [...formData.items, { menu_id: '', quantity: '' }] });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${config.API_BASE_URL}/customer/place-order`, formData);
      alert(response.data.message);
    } catch (error) {
      console.error('Error placing order', error);
      alert('Error placing order');
    }
  };

  return (
    <div className="place-order">
      <h2>Place Order</h2>
      <form onSubmit={handleSubmit}>
        <label>Customer Name:</label>
        <input type="text" name="customer_name" value={formData.customer_name} onChange={handleChange} required />

        <label>Phone Number:</label>
        <input type="text" name="phone_number" value={formData.phone_number} onChange={handleChange} required />

        <label>Total Amount:</label>
        <input type="number" name="total_amount" value={formData.total_amount} onChange={handleChange} required />

        <label>Other Order Details:</label>
        <input type="text" name="other_order_details" value={formData.other_order_details} onChange={handleChange} />

        {formData.items.map((item, index) => (
          <div key={index}>
            <label>Menu ID:</label>
            <input type="number" name="menu_id" value={item.menu_id} onChange={(e) => handleItemChange(index, e)} required />

            <label>Quantity:</label>
            <input type="number" name="quantity" value={item.quantity} onChange={(e) => handleItemChange(index, e)} required />
          </div>
        ))}
        <button type="button" onClick={handleAddItem}>Add Item</button>
        <button type="submit">Place Order</button>
      </form>
    </div>
  );
};

export default PlaceOrder;
