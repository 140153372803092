import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import config from '../config';
import BackButton from './BackButton';

const Login = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    is_verified: false
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState('');
  
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleForgotPassword = (e) => {
    e.preventDefault();
    setShowForgotPassword(true);
  };
  
  const handleForgotPasswordSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${config.API_BASE_URL}/auth/forgot_password`, { email: forgotPasswordEmail });
      setErrorMessage('Password reset link sent to your email.');
      setShowForgotPassword(false);
    } catch (error) {
      if (error.response) {
        if (error.response.status === 404) {
          setErrorMessage('Please check your email, if this account exist, you will receive an password reset email');
        }else{
          setErrorMessage('An error occurred. Please try again.');
        }
      }
    }
  };
  

const handleSubmit = async (e) => {
  e.preventDefault();
  try {
    const response = await axios.post(`${config.API_BASE_URL}/auth/login`, formData);
    sessionStorage.setItem('user_id', response.data.user_id);
    if (response.data && response.data.resturant){
      sessionStorage.setItem('restaurant_id', response.data.resturant.restaurant_id);
    }
    if (!response.data.subscription_id) {
      navigate('/select-subscription');
    } else {
      navigate('/dashboard');
    }
  } catch (error) {
      if (error.response) {
        if (error.response.status === 404) {
          setErrorMessage('User not found');
        } else if (error.response.status === 401) {
          setErrorMessage('Invalid password/username');
        } else if (error.response.status === 409) {
          setErrorMessage('Verify your Email');
        } else {
          setErrorMessage('An error occurred. Please try again.');
        }
      } else {
        setErrorMessage('An error occurred. Please try again.');
      }      
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
       <div className="max-w-6xl mx-auto px-4 py-12 relative">
          <BackButton />
    <div className="min-h flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8 bg-white p-8 rounded-xl shadow-lg">
        <div>
          <h2 className="text-center text-3xl font-extrabold text-gray-900">Login</h2>
          {errorMessage && (
            <div className="mt-4 p-4 bg-red-50 border border-red-200 rounded-lg">
              <p className="text-red-600 text-center text-sm">{errorMessage}</p>
            </div>
          )}
        </div>

        {!showForgotPassword ? (
          <form onSubmit={handleSubmit} className="mt-8 space-y-6">
            <div className="space-y-4">
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                  Email
                </label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
              </div>

              <div>
                <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                  Password
                </label>
                <input
                  type="password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  required
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-[#abbfff] hover:bg-[#4e7ab4] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors"
              >
                Login
              </button>
            </div>

            <div className="text-center">
              <button
                type="button"
                onClick={() => setShowForgotPassword(true)}
                className="text-sm text-blue-600 hover:text-blue-500 focus:outline-none focus:underline"
              >
                Forgot Password?
              </button>
            </div>
          </form>
        ) : (
          <div className="mt-8 space-y-6">
            <h3 className="text-xl font-bold text-gray-900 text-center">Forgot Password</h3>
            <form onSubmit={handleForgotPasswordSubmit} className="space-y-4">
              <div>
                <label htmlFor="resetEmail" className="block text-sm font-medium text-gray-700">
                  Email
                </label>
                <input
                  type="email"
                  value={forgotPasswordEmail}
                  onChange={(e) => setForgotPasswordEmail(e.target.value)}
                  required
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
              </div>

              <div className="flex gap-4">
                <button
                  type="submit"
                  className="flex-1 py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-[#abbfff] hover:bg-[#4e7ab4] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors"
                >
                  Reset Password
                </button>
                <button
                  type="button"
                  onClick={() => setShowForgotPassword(false)}
                  className="flex-1 py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors"
                >
                  Back to Login
                </button>
              </div>
            </form>
          </div>
        )}
      </div>
    </div>
    </div>
  );
};

export default Login;
