
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import config from "../config";
import axios from 'axios';
import './CustomerOrders.css';
import BackButton from './BackButton';


const CustomerOrders = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const customerId = location.state?.customerId || '';
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await axios.get(`${config.API_BASE_URL}/customer/${customerId}/orders`);
        setOrders(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching orders:', error);
        setLoading(false);
      }
    };

    console.log("customerId", customerId);
    
    if (customerId) {
      fetchOrders();
    }
  }, [customerId]);

  const handleTrackOrder = (order) => {
    console.log(order);
    
  navigate('/customer/order-status', {
    state: {
      orderId: order.order_id,
      restaurant_id: order.restaurant_id
    }
  });
  };

  if (loading) {
    return <div className="flex justify-center p-8">Loading orders...</div>;
  }

  return (
    <div className="container mx-auto p-4">
      <BackButton />
    <div className="max-w-3xl mx-auto p-6 bg-gray-50 rounded-lg shadow-lg mt-15">
      <h1 className="text-2xl font-bold mb-6">Your Active Orders</h1>
      <div className="grid gap-6">
        {orders.map((order) => (
          <div key={order.order_id} className="bg-white rounded-lg shadow p-6">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-semibold">{order.restaurant_name}</h2>
              <span className="px-4 py-2 rounded-full bg-blue-100 text-blue-800">
                {order.status}
              </span>
            </div>
            <div className="mb-4">
              <p className="text-gray-600">Order #{order.order_id}</p>
              <p className="text-gray-600">Placed on: {order.created_at}</p>
              {order.table_number && (
                <p className="text-gray-600">Table: {order.table_number}</p>
              )}
            </div>
            <div className="border-t pt-4">
              <h3 className="font-semibold mb-2">Order Items:</h3>
              <ul className="space-y-2">
                {order.items.map((item, index) => (
                  <li key={index} className="flex justify-between">
                    <span>
                      {item.product_name} x {item.quantity}
                    </span>
                    <span>${item.price.toFixed(2)}</span>
                  </li>
                ))}
              </ul>
              <div className="mt-4 pt-4 border-t flex justify-between">
                <span className="font-bold">Total Amount:</span>
                <span className="font-bold">${order.total_amount.toFixed(2)}</span>
              </div>
              <div className="mt-4 text-center">
                <button 
                  onClick={() => handleTrackOrder(order)}
                  className="track-order-btn"
                >
                  Track Order Status  
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
    </div>
  );
};

export default CustomerOrders;
