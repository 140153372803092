// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.track-order-container {
    font-family: 'Poppins', sans-serif;
    max-width: 600px;
    margin: 2rem auto;
    padding: 2rem;
    background: linear-gradient(to bottom, #aabeff, #ffffff);
    border-radius: 15px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    color: #ffffff;
}

.track-order-title {
    font-size: 2.5rem;
    font-weight: 600;
    text-align: center;
    margin-bottom: 1.5rem;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
}

.order-status {
    font-size: .8rem;
    text-align: center;
    padding: 1rem;
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 10px;
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
}

.error {
    background-color: #ff6b6b;
    color: #ffffff;
    padding: 1rem;
    border-radius: 10px;
    text-align: center;
    font-weight: 600;
    margin-top: 1rem;
}

@keyframes pulse {
    0% { transform: scale(1); }
    50% { transform: scale(1.05); }
    100% { transform: scale(1); }
}

.order-status {
    animation: pulse 2s infinite;
}
`, "",{"version":3,"sources":["webpack://./src/components/TrackOrder.css"],"names":[],"mappings":"AAEA;IACI,kCAAkC;IAClC,gBAAgB;IAChB,iBAAiB;IACjB,aAAa;IACb,wDAAwD;IACxD,mBAAmB;IACnB,0CAA0C;IAC1C,cAAc;AAClB;;AAEA;IACI,iBAAiB;IACjB,gBAAgB;IAChB,kBAAkB;IAClB,qBAAqB;IACrB,2CAA2C;AAC/C;;AAEA;IACI,gBAAgB;IAChB,kBAAkB;IAClB,aAAa;IACb,0CAA0C;IAC1C,mBAAmB;IACnB,kCAA0B;YAA1B,0BAA0B;AAC9B;;AAEA;IACI,yBAAyB;IACzB,cAAc;IACd,aAAa;IACb,mBAAmB;IACnB,kBAAkB;IAClB,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI,KAAK,mBAAmB,EAAE;IAC1B,MAAM,sBAAsB,EAAE;IAC9B,OAAO,mBAAmB,EAAE;AAChC;;AAEA;IACI,4BAA4B;AAChC","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');\n\n.track-order-container {\n    font-family: 'Poppins', sans-serif;\n    max-width: 600px;\n    margin: 2rem auto;\n    padding: 2rem;\n    background: linear-gradient(to bottom, #aabeff, #ffffff);\n    border-radius: 15px;\n    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);\n    color: #ffffff;\n}\n\n.track-order-title {\n    font-size: 2.5rem;\n    font-weight: 600;\n    text-align: center;\n    margin-bottom: 1.5rem;\n    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);\n}\n\n.order-status {\n    font-size: .8rem;\n    text-align: center;\n    padding: 1rem;\n    background-color: rgba(255, 255, 255, 0.2);\n    border-radius: 10px;\n    backdrop-filter: blur(5px);\n}\n\n.error {\n    background-color: #ff6b6b;\n    color: #ffffff;\n    padding: 1rem;\n    border-radius: 10px;\n    text-align: center;\n    font-weight: 600;\n    margin-top: 1rem;\n}\n\n@keyframes pulse {\n    0% { transform: scale(1); }\n    50% { transform: scale(1.05); }\n    100% { transform: scale(1); }\n}\n\n.order-status {\n    animation: pulse 2s infinite;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
