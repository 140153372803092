import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import axios from 'axios';
import config from '../config';
import './TrackOrder.css';



const TrackOrder = () => {
    const [searchParams] = useSearchParams();
    const restaurant_id = searchParams.get('restaurant_id');
    const order_id = searchParams.get('order_id');
    const [orderStatus, setOrderStatus] = useState('');
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchOrderStatus = async () => {
            try {
                const response = await axios.get(`${config.API_BASE_URL}/customer/order-status`, {
                    params: { restaurant_id, order_id }
                });
                setOrderStatus(response.data.status);
            } catch (error) {
                setError('Failed to fetch order status. Please try again later.');
            }
        };

        fetchOrderStatus();
    }, [restaurant_id, order_id]);

    return (
        <div>
            <h3>Track Your Order</h3>
            {error ? (
                <div className="error">{error}</div>
            ) : (
                <p>Your order status: {orderStatus}</p>
            )}
        </div>
    );
};

export default TrackOrder;
