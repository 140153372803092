import React, { useState, useEffect } from 'react'; 
import axios from 'axios';
import config from '../config';

function OrderManagement() {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [menuItems, setMenuItems] = useState([]);
  const [addMenuItemOrder, setAddMenuItemOrder] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const restaurantId = sessionStorage.getItem('restaurant_id');
  const [confirmDeleteOrderId, setConfirmDeleteOrderId] = useState(null);
  const [modifiedOrders, setModifiedOrders] = useState({});

  useEffect(() => {
    fetchOrders();
  }, [restaurantId]);

  const fetchOrders = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${config.API_BASE_URL}/owner/restaurant/${restaurantId}/orders`);
      setOrders(response.data);
    } catch (error) {
      setError('Failed to load orders. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const handleApproveOrder = (orderId) => {
    if (modifiedOrders[orderId]) {
      handleUpdateOrder(orderId);
    } else {
      approveOrder(orderId); 
    }
  };

  const approveOrder = async (orderId) => {
    try {
      const response = await axios.post(`${config.API_BASE_URL}/owner/restaurant/${restaurantId}/approve-order/${orderId}`);
      if (response.status === 200) {
        fetchOrders();
      }
    } catch (error) {
      console.error('Error approving order:', error);
    }
  };

  const handleUpdateOrder = async (orderId) => {
    try {
      const modifiedOrder = modifiedOrders[orderId];
      const response = await axios.put(`${config.API_BASE_URL}/customer/update-order/${orderId}`, {
        order_items: modifiedOrder.items,
        total_amount: calculateOrderTotal(modifiedOrder.items)
      });

      if (response.status === 200) {
        fetchOrders();
        setModifiedOrders((prev) => {
          const updated = { ...prev };
          delete updated[orderId];
          return updated;
        });
      }
    } catch (error) {
      console.error('Error updating order:', error);
    }
  };

  const calculateOrderTotal = (items) => {
    return items.reduce((total, item) => total + (item.price * item.quantity), 0);
  };

  const updateOrderItemQuantity = (orderId, itemId, newQuantity) => {
    setOrders((prevOrders) => {
      const updatedOrders = prevOrders.map(order => {
        if (order.order_id === orderId) {
          const updatedItems = order.items.map(item => 
            item.menu_item_id === itemId ? { ...item, quantity: newQuantity } : item
          );
          
          const newTotal = calculateOrderTotal(updatedItems);
          const modifiedOrder = { ...order, items: updatedItems, total_amount: newTotal };

          setModifiedOrders((prev) => ({
            ...prev,
            [orderId]: modifiedOrder
          }));

          return modifiedOrder;
        }
        return order;
      });
      return updatedOrders;
    });
  };

  const handleIncrementQuantity = (orderId, item) => {
    const newQuantity = item.quantity + 1;
    updateOrderItemQuantity(orderId, item.menu_item_id, newQuantity);
  };

  const handleDecrementQuantity = (orderId, item) => {
    if (item.quantity > 1) {
      const newQuantity = item.quantity - 1;
      updateOrderItemQuantity(orderId, item.menu_item_id, newQuantity);
    }
  };

  const handleGoBack = () => {
    setAddMenuItemOrder(null);
    setSearchTerm('');
  };

  const handleDeleteOrderItem = (orderId, itemId) => {
    setOrders(prevOrders => {
      const updatedOrders = prevOrders.map(order => {
        if (order.order_id === orderId) {
          const updatedItems = order.items.filter(item => item.menu_item_id !== itemId);
          return { ...order, items: updatedItems, total_amount: calculateOrderTotal(updatedItems) };
        }
        return order;
      });

      setModifiedOrders(prev => ({
        ...prev,
        [orderId]: {
          ...prev[orderId],
          items: updatedOrders.find(order => order.order_id === orderId).items
        }
      }));

      return updatedOrders;
    });
  };

  const getMenuItems = async (order) => {
    setAddMenuItemOrder(order);
    try {
      const response = await axios.get(`${config.API_BASE_URL}/menu/get-menu-items/${restaurantId}`);
      const allMenuItems = response.data;
      const existingItemIds = order.items.map(item => item.menu_item_id);
      const filteredMenuItems = allMenuItems.filter(item => !existingItemIds.includes(item.menu_id));
      setMenuItems(filteredMenuItems);
    } catch (error) {
      console.error('Error fetching menu items:', error);
    }
  };

  const handleDeleteOrder = (orderId) => {
    setConfirmDeleteOrderId(orderId);
  };

  const addMenuItemToOrder = (menuItem) => {
    const order = addMenuItemOrder;
    const updatedItems = [...order.items, { menu_item_id: menuItem.menu_id, quantity: 1, price: menuItem.price, product_name: menuItem.item_name }];
    const newTotal = calculateOrderTotal(updatedItems);

    setOrders(prevOrders => prevOrders.map(o => 
      o.order_id === order.order_id ? { ...o, items: updatedItems, total_amount: newTotal } : o
    ));

    setModifiedOrders(prev => ({
      ...prev,
      [order.order_id]: {
        ...order,
        items: updatedItems,
        total_amount: newTotal
      }
    }));
    
    setAddMenuItemOrder(null); 
  };

  const confirmDeleteOrder = async (orderId) => {
    try {
      const response = await axios.delete(`${config.API_BASE_URL}/owner/restaurant/${restaurantId}/delete-order/${orderId}`);
      if (response.status === 200) {
        sessionStorage.setItem('orderDeleted', 'true');
        fetchOrders();
      }
    } catch (error) {
      console.error('Error deleting order:', error);
    } finally {
      setConfirmDeleteOrderId(null);
    }
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
      <div className="w-full max-w-full overflow-x-hidden px-2 sm:px-4 md:px-6">
      <div className="max-w-6xl mx-auto">
      <h3 className="text-2xl md:text-3xl font-bold text-gray-800 text-center mb-4 md:mb-8">Order Management</h3>

      {loading ? (
        <div className="flex justify-center items-center py-8">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
        </div>
      ) : error ? (
        <div className="bg-red-50 text-red-600 p-4 rounded-lg text-center">{error}</div>
      ) : orders.length === 0 ? (
        <p className="text-gray-600 text-center text-lg">No Orders Present</p>
      ) : (
        <div className="space-y-3 md:space-y-4">
          {orders.map((order) => (
            <div key={order.order_id} className="bg-white rounded-xl shadow-lg p-3 md:p-6 w-full overflow-x-auto w-full overflow-x-auto">
              <div className="flex flex-col md:flex-row justify-between items-start md:items-center pb-3 md:pb-4 border-b border-gray-200 gap-2 md:gap-4">
                <div className="w-full md:w-auto space-y-1">
                  <p className="text-base md:text-lg font-semibold text-gray-800">{order.customer_name}</p>
                  <p className="text-xs md:text-sm text-gray-600">{order.phone_number}</p>
                </div>
                <div className="w-full md:w-auto text-left md:text-right space-y-1">
                  <p className="text-xs md:text-sm text-gray-600">Table: {order.table_number || 'Pick Up'}</p>
                  <p className="text-base md:text-lg font-bold text-[#abbfff]">${order.total_amount.toFixed(2)}</p>
                  <p className="text-sm font-medium">
                    <span className={`inline-block px-2 py-1 rounded-full text-xs
                      ${order.status === 'Completed' ? 'bg-green-100 text-green-800' : 
                        order.status === 'Cancelled' ? 'bg-red-100 text-red-800' :
                        'bg-blue-100 text-blue-800'}`}>
                      {order.status}
                    </span>
                  </p>
                  <p className="text-xs md:text-sm text-gray-500">Order #: {order.order_id}</p>
                </div>
              </div>

              <div className="mt-3 md:mt-4 space-y-2 min-w-full">
                {order.items.map(item => (
                  <div key={item.menu_item_id} className="flex flex-col md:flex-row items-start md:items-center justify-between py-2 gap-2 min-w-full">
                    <span className="text-sm md:text-base text-gray-800 font-medium">{item.product_name}</span>
                    <div className="flex flex-wrap items-center gap-2 md:gap-4">
                      <div className="flex items-center gap-2">
                        <button 
                          onClick={() => handleDecrementQuantity(order.order_id, item)}
                          className="w-7 h-7 md:w-8 md:h-8 rounded-full bg-gray-100 hover:bg-gray-200 flex items-center justify-center"
                        >-</button>
                        <span className="w-6 md:w-8 text-center text-sm md:text-base">{item.quantity}</span>
                        <button 
                          onClick={() => handleIncrementQuantity(order.order_id, item)}
                          className="w-7 h-7 md:w-8 md:h-8 rounded-full bg-gray-100 hover:bg-gray-200 flex items-center justify-center"
                        >+</button>
                      </div>
                      <span className="text-sm md:text-base text-gray-600">${item.price.toFixed(2)}</span>
                      <button 
                        onClick={() => handleDeleteOrderItem(order.order_id, item.menu_item_id)}
                        className="text-sm md:text-base text-red-500 hover:text-red-700"
                      >Delete</button>
                    </div>
                  </div>
                ))}
              </div>

              {addMenuItemOrder && addMenuItemOrder.order_id === order.order_id && (
                <div className="mt-3 md:mt-4 p-3 md:p-4 bg-gray-50 rounded-lg">
                  <div className="flex flex-col md:flex-row gap-2 md:gap-4 mb-3 md:mb-4">
                    <input
                      type="text"
                      placeholder="Search menu items..."
                      value={searchTerm}
                      onChange={handleSearch}
                      className="flex-1 p-2 text-sm md:text-base border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500"
                    />
                    <button 
                      onClick={handleGoBack}
                      className="px-4 py-2 text-sm md:text-base bg-gray-200 text-gray-700 rounded-md hover:bg-gray-300"
                    >Back</button>
                  </div>
                  
                  <div className="space-y-2">
                    {menuItems
                      .filter(item => item.item_name.toLowerCase().includes(searchTerm.toLowerCase()))
                      .map(item => (
                        <div key={item.menu_id} className="flex justify-between items-center p-2 hover:bg-gray-100 rounded-md">
                          <span className="text-sm md:text-base font-medium">{item.item_name}</span>
                          <div className="flex items-center gap-4">
                            <span className="text-sm md:text-base">${item.price.toFixed(2)}</span>
                            <button 
                              onClick={() => addMenuItemToOrder(item)}
                              className="px-3 py-1 text-sm md:text-base bg-blue-500 text-white rounded-md hover:bg-blue-600"
                            >Add</button>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              )}

              <div className="mt-4 md:mt-6 flex flex-col md:flex-row gap-2 md:gap-3">
                {order.status === 'Pending' && (
                  <button 
                    onClick={() => handleApproveOrder(order.order_id)}
                    className="w-full md:flex-1 py-2 text-sm md:text-base bg-[#abbfff] text-white rounded-md hover:bg-[#4e7ab4] transition-colors"
                  >
                    {modifiedOrders[order.order_id] ? 'Update Order' : 'Approve Order'}
                  </button>
                )}
                
                <button 
                  onClick={() => getMenuItems(order)}
                  className="w-full md:flex-1 py-2 text-sm md:text-base bg-[#abbfff] text-white rounded-md hover:bg-[#4e7ab4] transition-colors"
                >Add Items</button>

                {confirmDeleteOrderId === order.order_id ? (
                  <>
                    <button 
                      onClick={() => confirmDeleteOrder(order.order_id)}
                      className="w-full md:flex-1 py-2 text-sm md:text-base bg-red-500 text-white rounded-md hover:bg-red-600 transition-colors"
                    >Confirm Delete</button>
                    <button 
                      onClick={() => setConfirmDeleteOrderId(null)}
                      className="w-full md:flex-1 py-2 text-sm md:text-base bg-gray-500 text-white rounded-md hover:bg-gray-600 transition-colors"
                    >Cancel</button>
                  </>
                ) : (
                  <button 
                    onClick={() => handleDeleteOrder(order.order_id)}
                    className="w-full md:flex-1 py-2 text-sm md:text-base bg-[#abbfff] text-white rounded-md hover:bg-[#4e7ab4] transition-colors"
                  >Delete Order</button>
                )}
              </div>
            </div>
          ))}
        </div>
      )}
      </div>
    </div>
  );
}

export default OrderManagement;