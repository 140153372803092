import React, { useState, useCallback, useEffect } from 'react';
import axios from 'axios';
import config from "../config";

const QRCodeScanning = () => {
  const restaurant_id = sessionStorage.getItem('restaurant_id')
  const [restaurantId, setRestaurantId] = useState('');
  const [qrCodeUrl, setQrCodeUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    handleGenerateQR()
  },[])

  const handlePrint = () => {
    const printWindow = window.open('', '_blank');
    printWindow.document.write(`
      <html>
        <head>
          <title>Print QR Code</title>
          <style>
            body {
              display: flex;
              justify-content: center;
              align-items: center;
              min-height: 100vh;
              margin: 0;
              padding: 20px;
              box-sizing: border-box;
            }
            img {
              max-width: 300px;
              height: auto;
            }
          </style>
        </head>
        <body>
          <img src="${qrCodeUrl}" alt="QR Code" />
          <script>
            window.onload = () => {
              window.print();
              window.onafterprint = () => window.close();
            }
          </script>
        </body>
      </html>
    `);
    printWindow.document.close();
  };

  const handleGenerateQR = useCallback(async () => {
    if (!restaurant_id) {
      setError('Error generating QR code');
      return;
    }

    setIsLoading(true);
    setError('');
    setQrCodeUrl('');

    try {
      const response = await axios.post(
        `${config.API_BASE_URL}/menu/generate-qr-code`,
        { restaurant_id: restaurant_id },
        {
          responseType: 'blob',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'image/png'
          }
        }
      );
      const contentType = response.headers['content-type'];
      
      if (contentType.includes('application/json')) {
        const errorResponse = await response.data.text();
        const jsonError = JSON.parse(errorResponse);

        if (jsonError.statusText === "NO_MENU_ITEMS") {
          setError('Please create a menu first.');
        } else {
          setError('Failed to generate QR code. Please try again.');
        }
      } else if (contentType.includes('image/png')) {
        const url = URL.createObjectURL(new Blob([response.data], { type: 'image/png' }));
        setQrCodeUrl(url);
      } else {
        setError('Failed to generate QR code. Please try again.');
      }
    } catch (error) {
      setError('Failed to generate QR code. Please try again.');
    } finally {
      setIsLoading(false);
    }
  }, [restaurant_id]);

  return (
    <div className="max-w-2xl mx-auto p-6">
      <div className="bg-white rounded-xl shadow-lg p-8">
        <h2 className="text-2xl font-bold text-gray-800 text-center mb-8">Generate QR Code</h2>
        
        {error && (
          <div className="mb-6 p-4 bg-red-50 border border-red-200 rounded-lg">
            <p className="text-red-600 text-center">{error}</p>
          </div>
        )}

        {isLoading && (
          <div className="flex justify-center items-center py-8">
            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
            <p className="ml-3 text-gray-600">Generating QR Code...</p>
          </div>
        )}

        {qrCodeUrl && (
          <div className="space-y-6">
            <div className="flex justify-center">
              <img 
                src={qrCodeUrl} 
                alt="QR Code" 
                className="max-w-[300px] rounded-lg shadow-md"
              />
            </div>

            <div className="flex flex-col sm:flex-row justify-center gap-4">
              <a
                href={qrCodeUrl}
                download={`qr_code_${restaurant_id}.png`}
                className="inline-flex justify-center items-center px-6 py-3 bg-[#abbfff] text-white rounded-lg hover:bg-[#4e7ab4] transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
              >
                <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
                </svg>
                Download QR Code
              </a>

              <button
                onClick={handlePrint}
                className="inline-flex justify-center items-center px-6 py-3 bg-gray-800 text-white rounded-lg hover:bg-gray-900 transition-colors focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
              >
                <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z" />
                </svg>
                Print QR Code
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default QRCodeScanning;
