import React, { useState, useEffect } from "react";
import axios from "axios";
import config from "../config";

const OrderSummary = () => {
  const [orders, setOrders] = useState([]);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [stats, setStats] = useState({ totalOrders: 0, paidOrders: 0 });
  const [sortOrder, setSortOrder] = useState("desc");
  const [filterOption, setFilterOption] = useState("pending");
  const restaurant_id = sessionStorage.getItem("restaurant_id");
  const [expandedRows, setExpandedRows] = useState(new Set());


  const handleMarkSelectedPaid = () => {
    selectedOrders.forEach((orderId) =>
      handlePaymentChange(orderId, "Completed")
    );
    setSelectedOrders([]);
  };

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        if (!restaurant_id) {
          setOrders([]);
          return;
        }

        const response = await axios.get(
          `${config.API_BASE_URL}/customer/orders/${restaurant_id}`
        );
        const sortedOrders = [...response.data].sort((a, b) => {
          const dateA = new Date(a.order_date);
          const dateB = new Date(b.order_date);
          return dateB - dateA;
        });
        setOrders(sortedOrders);
        calculateStats(sortedOrders);
      } catch (error) {
        console.log("Error details:", error.response);
        setOrders([]);
        calculateStats([]);
      }
    };
  
    fetchOrders();
  }, [restaurant_id]);

  const formatOrderTime = (orderDate) => {
    const date = new Date(orderDate);
    const today = new Date();
    
    if (window.innerWidth < 768) {
      if (date.toDateString() === today.toDateString()) {
        return date.toLocaleTimeString('en-US', { 
          hour: 'numeric', 
          minute: '2-digit'
        });
      }
      return date.toLocaleDateString('en-US', { 
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: '2-digit'
      });
    }
    
    return date.toLocaleDateString('en-US', { 
      weekday: 'short',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: '2-digit'
    });
  };

  const toggleRowExpand = (orderId) => {
    setExpandedRows(prev => {
      const newSet = new Set(prev);
      if (newSet.has(orderId)) {
        newSet.delete(orderId);
      } else {
        newSet.add(orderId);
      }
      return newSet;
    });
  };
  
  const calculateStats = (orders) => {
    const totalOrders = orders.length;
    const paidOrders = orders.filter(
      (order) => order.status === "Completed"
    ).length;
    setStats({ totalOrders, paidOrders });
  };

  const handleSortChange = (e) => {
    const newSortOrder = e.target.value;
    setSortOrder(newSortOrder);
    const sortedOrders = [...orders].sort((a, b) => {
      const dateA = new Date(a.order_date);
      const dateB = new Date(b.order_date);
      return newSortOrder === "asc" ? dateA - dateB : dateB - dateA;
    });
    setOrders(sortedOrders);
  };

  const handleFilterChange = (e) => {
    setFilterOption(e.target.value);
  };

  const handlePaymentChange = async (orderId, status) => {
    try {
      await axios.post(
        `${config.API_BASE_URL}/kitchen/restaurant/${restaurant_id}/update-order-status/${orderId}`,
        { status: status }
      );
      setOrders((prevOrders) =>
        prevOrders.map((order) =>
          order.order_id === orderId ? { ...order, status: status } : order
        )
      );
      calculateStats(orders);
    } catch (error) {
      console.error("Error updating order status:", error);
    }
  };

  const formatOrderDetails = (order) => {
    const date = new Date(order.order_date);
    
    return (
      <div className="flex flex-col">
        <span className="font-medium text-gray-900">#{order.order_id}</span>
        <span className="text-xs text-gray-500">
          {date.toLocaleDateString('en-US', {
            month: 'short',
            day: 'numeric',
            year: 'numeric'
          })}
          {' • '}
          {date.toLocaleTimeString('en-US', {
            hour: 'numeric',
            minute: '2-digit'
          })}
        </span>
      </div>
    );
  };

  const handleSelectOrder = (orderId) => {
    setSelectedOrders((prevSelected) =>
      prevSelected.includes(orderId)
        ? prevSelected.filter((id) => id !== orderId)
        : [...prevSelected, orderId]
    );
  };

  const filteredOrders = orders.filter((order) => {
    if (filterOption === "pending") {
      return order.status !== "Completed" && order.status !== "Cancelled";
    } else if (filterOption === "completed") {
      return order.status === "Completed";
    } else if (filterOption === "cancelled") {
      return order.status === "Cancelled";
    }
    return true;
  });

  const totalSelectedAmount = filteredOrders
    .filter((order) => selectedOrders.includes(order.order_id))
    .reduce((total, order) => total + order.total_amount, 0);

  return (
    <div className="w-full px-4 md:max-w-6xl mx-auto p-2 md:p-6 bg-white rounded-xl shadow-lg">
      <h2 className="text-2xl md:text-3xl font-bold text-gray-800 text-center mb-4 md:mb-8">
        Order Summary
      </h2>

      <div className="flex flex-col md:flex-row gap-3 md:gap-4 mb-4 md:mb-6">
        <div className="w-full md:flex-1">
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Filter by:
          </label>
          <select
            className="w-full p-2 text-sm md:text-base border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500"
            value={filterOption}
            onChange={handleFilterChange}
          >
            <option value="pending">Pending Orders</option>
            <option value="completed">Completed Orders</option>
            <option value="cancelled">Cancelled Orders</option>
            <option value="all">All Orders</option>
          </select>
        </div>

        <div className="w-full md:flex-1">
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Sort by:
          </label>
          <select
            className="w-full p-2 text-sm md:text-base border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500"
            value={sortOrder}
            onChange={handleSortChange}
          >
            <option value="">Sort by Date</option>
            <option value="asc">Oldest First</option>
            <option value="desc">Newest First</option>
          </select>
        </div>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 md:gap-6 mb-4 md:mb-8">
        <div className="bg-gradient-to-br from-blue-50 to-white p-4 md:p-6 rounded-xl shadow-md">
          <h3 className="text-base md:text-lg font-semibold text-gray-700 mb-2">
            Total Orders
          </h3>
          <p className="text-2xl md:text-3xl font-bold text-blue-600">
            {stats.totalOrders}
          </p>
        </div>
        <div className="bg-gradient-to-br from-blue-50 to-white p-4 md:p-6 rounded-xl shadow-md">
          <h3 className="text-base md:text-lg font-semibold text-gray-700 mb-2">
            Paid Orders
          </h3>
          <p className="text-2xl md:text-3xl font-bold text-blue-600">
            {stats.paidOrders}
          </p>
        </div>
      </div>
      <div className="overflow-x-auto -mx-4 md:mx-0">
        <div className="inline-block min-w-full align-middle">
          <table className="min-w-full">
          <thead className="bg-gray-50">
            <tr>
              <th className="p-2 md:p-4 text-left text-xs md:text-sm font-semibold text-gray-600">
                Select
              </th>
              <th className="p-2 md:p-4 text-left text-xs md:text-sm font-semibold text-gray-600">
                Table
              </th>
              <th className="p-2 md:p-4 text-left text-xs md:text-sm font-semibold text-gray-600">
                Order Details
              </th>
              <th className="p-2 md:p-4 text-left text-xs md:text-sm font-semibold text-gray-600">
                Customer
              </th>
              <th className="p-2 md:p-4 text-left text-xs md:text-sm font-semibold text-gray-600">
                Total
              </th>
              <th className="p-2 md:p-4 text-left text-xs md:text-sm font-semibold text-gray-600">
                Status
              </th>
              <th className="p-2 md:p-4 text-left text-xs md:text-sm font-semibold text-gray-600">
                Action
              </th>
            </tr>
          </thead>
            <tbody className="divide-y divide-gray-200">
              {filteredOrders.map((order) => (
                <tr key={order.order_id} className="hover:bg-gray-50">
                <td className="p-2 md:p-4 text-xs md:text-sm">
                {order.status !== "Completed" && (
                  <input
                    type="checkbox"
                    checked={selectedOrders.includes(order.order_id)}
                    onChange={() => handleSelectOrder(order.order_id)}
                    className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                      />
                    )}
                    </td>
                  <td className="p-2 md:p-4 text-xs md:text-sm">
                    {order.table_number ? order.table_number : "Pick-up"}
                  </td>
                  <td className="p-2 md:p-4">
                    <div className="flex flex-col">
                      <div className="flex items-center ml-4 gap-2">
                        <span className="font-medium text-center text-gray-900">
                        #{order.order_id}
                        </span>
                        <button 
                          className="md:hidden rounded-full p-1 hover:bg-gray-100"
                          onClick={() => toggleRowExpand(order.order_id)}
                        >
                          <svg 
                            className={`w-4 h-4 transition-transform ${
                              expandedRows.has(order.order_id) ? 'rotate-180' : ''
                            }`} 
                            fill="none" 
                            stroke="currentColor" 
                            viewBox="0 0 24 24"
                          >
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                          </svg>
                        </button>
                      </div>
                      <span className={`
                        text-xs text-gray-500
                        md:block
                        ${expandedRows.has(order.order_id) ? 'block' : 'hidden'}
                      `}>
                        {new Date(order.order_date).toLocaleDateString('en-US', {
                          month: 'short',
                          day: 'numeric',
                          year: 'numeric'
                        })}
                        {' • '}
                        {new Date(order.order_date).toLocaleTimeString('en-US', {
                          hour: 'numeric',
                          minute: '2-digit'
                        })}
                      </span>
                    </div>
                  </td>
                  <td className="p-2 md:p-4 text-xs md:text-sm">
                    {order.customer_name}
                  </td>
                  <td className="p-2 md:p-4 text-xs md:text-sm">
                    ${order.total_amount.toFixed(2)}
                  </td>
                  <td className="p-2 md:p-4">
                    <span
                      className="px-2 py-1 rounded-full text-xs font-medium whitespace-nowrap
                      ${order.status === 'Completed' ? 'bg-green-100 text-green-800' : 
                        order.status === 'Cancelled' ? 'bg-red-100 text-red-800' :
                        order.status === 'Ready' ? 'bg-yellow-100 text-yellow-800' :
                        'bg-blue-100 text-blue-800'}"
                    >
                      {order.status}
                    </span>
                  </td>
                  <td className="p-2 md:p-4">
                    {order.status === "Approved" && (
                      <button
                        onClick={() =>
                          handlePaymentChange(order.order_id, "Ready")
                        }
                        className="px-2 md:px-3 py-1 text-xs md:text-sm bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors"
                      >
                        Mark Ready
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {selectedOrders.length > 0 && (
        <div className="mt-4 md:mt-6 p-3 md:p-4 bg-gray-50 rounded-lg flex flex-col md:flex-row justify-between items-start md:items-center gap-3">
          <p className="text-base md:text-lg font-semibold text-gray-800">
            Total Selected: ${totalSelectedAmount.toFixed(2)}
          </p>
          <button
            onClick={handleMarkSelectedPaid}
            className="w-full md:w-auto px-4 py-2 text-sm md:text-base bg-green-500 text-white rounded-md hover:bg-green-600 transition-colors"
          >
            Mark Selected as Paid
          </button>
        </div>
      )}
    </div>
  );
};

export default OrderSummary;
