import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import config from '../config';

const VerifyEmail = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get('email');
  
  const handleLogin = () => {
    window.location.href = '/login';
  };

  useEffect(() => {
    const verifyEmail = async () => {
      try {
        const response = await axios.get(`${config.API_BASE_URL}/auth/verify-email/${email}`);
        if (response.data.status) {
          alert('Email verified successfully. You can now login.');
        } else {
          alert('Email verification failed. Please try again.');
        }
      } catch (error) {
        console.error('Error verifying email', error);
      }
    };
    verifyEmail();
  }, [email]);

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-b from-[#aabeff] to-white font-sans">
      <div className="bg-white/90 rounded-3xl p-10 shadow-lg text-center max-w-[400px] w-[90%]">
        <div className="text-6xl text-green-500 mb-5">✓</div>
        <h2 className="text-2xl font-bold text-gray-700 mb-5">
          Email Verification
        </h2>
        <p className="text-gray-600 text-base leading-relaxed mb-4">
          Your Email has been verified
        </p>
        <p className="text-gray-600 text-base leading-relaxed mb-5">
          You can now login
        </p>
        <button 
          onClick={handleLogin}
          className="bg-[#6e8efb] text-white px-6 py-3 text-base rounded-full 
                   cursor-pointer transition-all duration-300 ease-in-out 
                   hover:bg-[#5c7cfa] hover:-translate-y-0.5 hover:shadow-lg"
        >
          Login
        </button>
      </div>
    </div>
  );
};

export default VerifyEmail;
