import React, { useState, useEffect } from "react";
import { Link, Route, Routes, useNavigate, useLocation } from "react-router-dom";
import CreateRestaurant from "./CreateRestaurant";
import EditRestaurant from "./EditRestaurant";
import UserProfile from "./UserProfile";
import PlaceOrder from "./PlaceOrder";
import OrderSummary from "./OrderSummary";
import QRCodeScanning from "./QRCodeScanning";
import MenuDisplay from "./MenuDisplay";
import MenuManagement from "./MenuManagement";
import OrderManagement from './OrderManagement';
import KitchenManagement from './KitchenManagement';
import AuthGuard from "../guard/AuthGuard";
import axios from "axios";
import config from "../config";

const Dashboard = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [hasRestaurant, setHasRestaurant] = useState(false);
  const [restaurant, setRestaurant] = useState({});
  const [selectedMenuItem, setSelectedMenuItem] = useState('');
  const [message, setMessage] = useState('');
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    fetchRestaurantStatus();
    if (location.pathname === '/dashboard') {
      setSelectedMenuItem('order-summary');
    }
    setSelectedMenuItem(location.pathname.split('/')[2] || 'order-summary');
  }, [location]);

  const fetchRestaurantStatus = async () => {
    try {
      const response = await axios.get(
        `${config.API_BASE_URL}/restaurant/get-restaurant/${sessionStorage.getItem("restaurant_id")}`
      );
      sessionStorage.setItem("restaurant_id", response.data.data.restaurant_id);
      setRestaurant(response.data.data);
      setHasRestaurant(response.data.status);
    } catch (error) {
      console.error("Error fetching restaurant status:", error);
    }
  };

  const handleLogout = async () => {
    sessionStorage.clear();
    navigate("/");
  };

  const handleMenuItemClick = (path) => {
    setSelectedMenuItem(path);
    navigate(path);
    setIsMenuOpen(false);
    window.scrollTo(0, 0);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="flex w-full max-w-7xl mx-auto p-2 bg-gray-50 rounded-xl shadow-lg min-h-screen">
      <button 
        className={`fixed top-15 left-5 z-50 p-3 rounded-lg bg-white shadow-md md:hidden ${isMenuOpen ? 'bg-gray-100' : ''}`}
        onClick={toggleMenu}
      >
        <div className="w-6 h-0.5 bg-gray-800 mb-1.5"></div>
        <div className="w-6 h-0.5 bg-gray-800 mb-1.5"></div>
        <div className="w-6 h-0.5 bg-gray-800"></div>
      </button>

      <div className={`
        fixed md:static w-64 h-screen bg-white shadow-lg transition-transform duration-300 ease-in-out z-40
        ${isMenuOpen ? 'translate-x-0' : '-translate-x-full'} 
        md:translate-x-0 md:mr-6 p-6 rounded-xl h-full
      `}>
        <h2 className="text-2xl font-bold text-gray-800 mb-8">Dashboard</h2>
        {message && <p className="text-sm text-blue-600 mb-4">{message}</p>}
        
        <nav className="space-y-2">
          <div
            onClick={() => handleMenuItemClick('order-summary')}
            className={`block px-4 py-3 rounded-lg transition-all cursor-pointer ${
              selectedMenuItem === 'order-summary'
                ? 'bg-[#abbfff] text-white shadow-md'
                : 'hover:bg-gray-100 text-gray-700'
            } ${!hasRestaurant ? 'opacity-50 cursor-not-allowed' : ''}`}
            style={{ pointerEvents: hasRestaurant ? 'auto' : 'none' }}
          >
            Order Summary
          </div>

          {hasRestaurant ? (
            <div
              onClick={() => handleMenuItemClick('edit-restaurant')}
              className={`block px-4 py-3 rounded-lg transition-all cursor-pointer ${
                selectedMenuItem === 'edit-restaurant'
                  ? 'bg-[#abbfff] text-white shadow-md'
                  : 'hover:bg-gray-100 text-gray-700'
              }`}
            >
              Edit Restaurant
            </div>
          ) : (
            <div
              onClick={() => handleMenuItemClick('create-restaurant')}
              className={`block px-4 py-3 rounded-lg transition-all cursor-pointer ${
                selectedMenuItem === 'create-restaurant'
                  ? 'bg-[#abbfff] text-white shadow-md'
                  : 'hover:bg-gray-100 text-gray-700'
              }`}
            >
              Create Restaurant
            </div>
          )}

          {['profile', 'generate-qr-code', 'menu-management', 'order-management', 'kitchen-management'].map((item) => (
            <div
              key={item}
              onClick={() => handleMenuItemClick(item)}
              className={`block px-4 py-3 rounded-lg transition-all cursor-pointer ${
                selectedMenuItem === item
                  ? 'bg-[#abbfff] text-white shadow-md'
                  : 'hover:bg-gray-100 text-gray-700'
              } ${!hasRestaurant ? 'opacity-50 cursor-not-allowed' : ''}`}
              style={{ pointerEvents: hasRestaurant ? 'auto' : 'none' }}
            >
              {item.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
            </div>
          ))}
        </nav>

        <button
          onClick={handleLogout}
          className="w-full mt-8 bg-gradient-to-r bg-[#abbfff] hover:bg-[#4e7ab4] text-white py-3 px-6 rounded-lg font-semibold shadow-md transition-all transform hover:scale-105"
        >
          Log Out
        </button>
      </div>

      <div className="flex-1 md:ml-6 mt-16 md:mt-0">
        <Routes>
          <Route path="/" element={<AuthGuard component={<OrderSummary />} />} />
          {hasRestaurant ? (
            <Route path="edit-restaurant" element={<AuthGuard component={<EditRestaurant />} />} />
          ) : (
            <Route path="create-restaurant" element={<AuthGuard component={<CreateRestaurant />} />} />
          )}
          <Route path="profile" element={<AuthGuard component={<UserProfile />} />} />
          <Route path="place-order" element={<AuthGuard component={<PlaceOrder />} />} />
          <Route path="order-summary" element={<AuthGuard component={<OrderSummary />} />} />
          <Route path="generate-qr-code" element={<AuthGuard component={<QRCodeScanning />} />} />
          <Route path="menus" element={<AuthGuard component={<MenuDisplay />} />} />
          <Route path="menu-management" element={<AuthGuard component={<MenuManagement />} />} />
          <Route path="order-management" element={<AuthGuard component={<OrderManagement />} />} />
          <Route path="kitchen-management" element={<AuthGuard component={<KitchenManagement />} />} />
        </Routes>
      </div>
    </div>
  );
};

export default Dashboard;
