// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.reset-password {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .reset-password h2 {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
  }
  
  .reset-password form {
    display: flex;
    flex-direction: column;
  }
  
  .reset-password label {
    margin-bottom: 5px;
    color: #555;
  }
  
  .reset-password input {
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .reset-password button {
    padding: 10px;
    background-color: #007bff;
    margin-bottom: 5px;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .reset-password button:hover {
    background-color: #0056b3;
  }
  
  .error-message {
    color: #000000;
    margin-bottom: 15px;
    text-align: center;
  }
  
  .success-message {
    color: #28a745;
    margin-bottom: 15px;
    text-align: center;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/ResetPassword.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,cAAc;IACd,aAAa;IACb,yBAAyB;IACzB,kBAAkB;IAClB,uCAAuC;EACzC;;EAEA;IACE,kBAAkB;IAClB,WAAW;IACX,mBAAmB;EACrB;;EAEA;IACE,aAAa;IACb,sBAAsB;EACxB;;EAEA;IACE,kBAAkB;IAClB,WAAW;EACb;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,sBAAsB;IACtB,kBAAkB;EACpB;;EAEA;IACE,aAAa;IACb,yBAAyB;IACzB,kBAAkB;IAClB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,sCAAsC;EACxC;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,cAAc;IACd,mBAAmB;IACnB,kBAAkB;EACpB;;EAEA;IACE,cAAc;IACd,mBAAmB;IACnB,kBAAkB;EACpB","sourcesContent":[".reset-password {\n    max-width: 400px;\n    margin: 0 auto;\n    padding: 20px;\n    background-color: #f9f9f9;\n    border-radius: 8px;\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n  }\n  \n  .reset-password h2 {\n    text-align: center;\n    color: #333;\n    margin-bottom: 20px;\n  }\n  \n  .reset-password form {\n    display: flex;\n    flex-direction: column;\n  }\n  \n  .reset-password label {\n    margin-bottom: 5px;\n    color: #555;\n  }\n  \n  .reset-password input {\n    padding: 10px;\n    margin-bottom: 15px;\n    border: 1px solid #ddd;\n    border-radius: 4px;\n  }\n  \n  .reset-password button {\n    padding: 10px;\n    background-color: #007bff;\n    margin-bottom: 5px;\n    color: white;\n    border: none;\n    border-radius: 4px;\n    cursor: pointer;\n    transition: background-color 0.3s ease;\n  }\n  \n  .reset-password button:hover {\n    background-color: #0056b3;\n  }\n  \n  .error-message {\n    color: #000000;\n    margin-bottom: 15px;\n    text-align: center;\n  }\n  \n  .success-message {\n    color: #28a745;\n    margin-bottom: 15px;\n    text-align: center;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
