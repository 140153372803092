import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import BackButton from "./BackButton";
import CartIcon from "./Carticon";

const Contact = () => {
  const navigate = useNavigate();

  // Initialize cart state from localStorage
  const [cart, setCart] = useState(() => {
    const savedCart = localStorage.getItem("currentCart");
    return savedCart ? JSON.parse(savedCart) : [];
  });

  // Cart related states
  const [showCartModal, setShowCartModal] = useState(false);
  const [touching, setTouching] = useState(false);
  const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });
  const [cartPosition, setCartPosition] = useState(() => {
    const saved = localStorage.getItem("cartPosition");
    return saved ? JSON.parse(saved) : { x: window.innerWidth - 100, y: 20 };
  });

  const [touchPosition, setTouchPosition] = useState({
    x: window.innerWidth - 80,
    y: window.innerHeight / 2,
  });

  // Monitor cart changes
  useEffect(() => {
    const savedCart = localStorage.getItem("currentCart");
    if (savedCart) {
      setCart(JSON.parse(savedCart));
      console.log("Cart loaded:", JSON.parse(savedCart)); // Debug log
    }
  }, []);

  // Save cart changes
  useEffect(() => {
    localStorage.setItem("currentCart", JSON.stringify(cart));
    console.log("Cart updated:", cart); // Debug log
  }, [cart]);

  // Scroll to top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  // Add these handler functions inside the Contact component:

  const handleDragStart = (e) => {
    const rect = e.target.getBoundingClientRect();
    const offsetX = e.clientX - rect.left;
    const offsetY = e.clientY - rect.top;
    e.dataTransfer.setData("text/plain", `${offsetX},${offsetY}`);
  };

  const handleDrag = (e) => {
    if (e.clientX && e.clientY) {
      const [offsetX, offsetY] = e.dataTransfer
        .getData("text/plain")
        .split(",");
      const x = Math.min(
        Math.max(0, e.clientX - offsetX),
        window.innerWidth - 100
      );
      const y = Math.min(
        Math.max(0, e.clientY - offsetY),
        window.innerHeight - 100
      );
      setCartPosition({ x, y });
      localStorage.setItem("cartPosition", JSON.stringify({ x, y }));
    }
  };

  const handleMobileTouchStart = (e) => {
    const touch = e.touches[0];
    setTouching(true);
    setDragOffset({
      x: touch.clientX - touchPosition.x,
      y: touch.clientY - touchPosition.y,
    });
  };

  const handleMobileTouchMove = (e) => {
    if (touching) {
      const touch = e.touches[0];
      const newX = Math.min(
        Math.max(0, touch.clientX - dragOffset.x),
        window.innerWidth - 80
      );
      const newY = Math.min(
        Math.max(0, touch.clientY - dragOffset.y),
        window.innerHeight - 80
      );
      setTouchPosition({ x: newX, y: newY });
    }
  };

  const handleMobileTouchEnd = () => setTouching(false);

  const handleUpdateCart = (menuId, quantity) => {
    quantity <= 0
      ? setCart((prevCart) =>
          prevCart.filter((item) => item.menu_id !== menuId)
        )
      : setCart((prevCart) =>
          prevCart.map((item) =>
            item.menu_id === menuId ? { ...item, quantity } : item
          )
        );
  };

  const handleAddToCart = (item) => {
    setCart((prevCart) => {
      const existingItem = prevCart.find((i) => i.menu_id === item.menu_id);
      return existingItem
        ? prevCart.map((i) =>
            i.menu_id === item.menu_id ? { ...i, quantity: i.quantity + 1 } : i
          )
        : [...prevCart, { ...item, quantity: 1 }];
    });
  };

  const calculateTotal = () =>
    cart
      .reduce((total, item) => total + item.price * item.quantity, 0)
      .toFixed(2);

  const handleCheckout = () => {
    navigate("/cart", {
      state: {
        cart,
        restaurant_id: localStorage.getItem("restaurant_id"),
      },
    });
  };

  return (
    <div className="relative">
      <div className="max-w-6xl mx-auto px-4 py-12">
        <BackButton />
        <div className="max-w-6xl mx-auto px-4 py-12">
          <h1 className="text-3xl font-bold text-gray-800 text-center mb-12">
            Contact Us
          </h1>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
            <div className="bg-white rounded-xl shadow-lg p-8">
              <h2 className="text-2xl font-semibold text-gray-800 mb-4">
                Get in Touch
              </h2>
              <p className="text-gray-600 mb-8">We'd love to hear from you!</p>

              <div className="space-y-6">
                <div className="flex items-center space-x-4">
                  <div className="w-10 h-10 bg-blue-100 rounded-full flex items-center justify-center">
                    <svg
                      className="w-5 h-5 text-blue-600"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                      />
                    </svg>
                  </div>
                  <div>
                    <p className="text-gray-800 font-medium">Email</p>
                    <p className="text-gray-600">support@dinefaster.com</p>
                  </div>
                </div>

                <div className="flex items-center space-x-4">
                  <div className="w-10 h-10 bg-blue-100 rounded-full flex items-center justify-center">
                    <svg
                      className="w-5 h-5 text-blue-600"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                      />
                    </svg>
                  </div>
                  <div>
                    <p className="text-gray-800 font-medium">Phone</p>
                    <p className="text-gray-600">+1 (555) 123-4567</p>
                  </div>
                </div>

                <div className="flex items-center space-x-4">
                  <div className="w-10 h-10 bg-blue-100 rounded-full flex items-center justify-center">
                    <svg
                      className="w-5 h-5 text-blue-600"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                      />
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                      />
                    </svg>
                  </div>
                  <div>
                    <p className="text-gray-800 font-medium">Address</p>
                    <p className="text-gray-600">
                      123 Business Street, Tech City, TC 12345
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/* Contact Form */}
            <form className="bg-white rounded-xl shadow-lg p-8">
              <div className="space-y-6">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Name
                  </label>
                  <input
                    type="text"
                    required
                    className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    placeholder="Your name"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Email
                  </label>
                  <input
                    type="email"
                    required
                    className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    placeholder="your@email.com"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Subject
                  </label>
                  <input
                    type="text"
                    required
                    className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    placeholder="How can we help?"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Message
                  </label>
                  <textarea
                    required
                    rows="4"
                    className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    placeholder="Your message..."
                  ></textarea>
                </div>

                <button
                  type="submit"
                  className="w-full py-3 px-4 bg-blue-600 text-white font-medium rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-colors"
                >
                  Send Message
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* Move CartIcon here, directly under the main container */}
      {cart.length > 0 && (
        <CartIcon
          cart={cart}
          showCartModal={showCartModal}
          setShowCartModal={setShowCartModal}
          touching={touching}
          touchPosition={touchPosition}
          handleMobileTouchStart={handleMobileTouchStart}
          handleMobileTouchMove={handleMobileTouchMove}
          handleMobileTouchEnd={handleMobileTouchEnd}
          cartPosition={cartPosition}
          handleDragStart={handleDragStart}
          handleDrag={handleDrag}
          handleUpdateCart={handleUpdateCart}
          handleAddToCart={handleAddToCart}
          calculateTotal={calculateTotal}
          handleCheckout={handleCheckout}
        />
      )}
    </div>
  );
};

export default Contact;
