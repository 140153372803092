// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.place-order {
    max-width: 600px;
    margin: 20px auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    background-color: #f9f9f9;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .place-order h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .place-order form {
    display: flex;
    flex-direction: column;
  }
  
  .place-order label {
    margin-bottom: 5px;
  }
  
  .place-order input[type="text"],
  .place-order input[type="number"] {
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .place-order button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    margin-top: 10px;
  }
  
  .place-order button:hover {
    background-color: #0056b3;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/PlaceOrder.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,iBAAiB;IACjB,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,yBAAyB;IACzB,uCAAuC;EACzC;;EAEA;IACE,kBAAkB;IAClB,mBAAmB;EACrB;;EAEA;IACE,aAAa;IACb,sBAAsB;EACxB;;EAEA;IACE,kBAAkB;EACpB;;EAEA;;IAEE,aAAa;IACb,mBAAmB;IACnB,sBAAsB;IACtB,kBAAkB;EACpB;;EAEA;IACE,kBAAkB;IAClB,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,eAAe;IACf,kBAAkB;IAClB,gBAAgB;EAClB;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":[".place-order {\n    max-width: 600px;\n    margin: 20px auto;\n    padding: 20px;\n    border: 1px solid #ccc;\n    border-radius: 10px;\n    background-color: #f9f9f9;\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n  }\n  \n  .place-order h2 {\n    text-align: center;\n    margin-bottom: 20px;\n  }\n  \n  .place-order form {\n    display: flex;\n    flex-direction: column;\n  }\n  \n  .place-order label {\n    margin-bottom: 5px;\n  }\n  \n  .place-order input[type=\"text\"],\n  .place-order input[type=\"number\"] {\n    padding: 10px;\n    margin-bottom: 10px;\n    border: 1px solid #ccc;\n    border-radius: 5px;\n  }\n  \n  .place-order button {\n    padding: 10px 20px;\n    background-color: #007bff;\n    color: white;\n    border: none;\n    cursor: pointer;\n    border-radius: 5px;\n    margin-top: 10px;\n  }\n  \n  .place-order button:hover {\n    background-color: #0056b3;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
