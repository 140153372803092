import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import config from "../config";
import CustomerLogin from "./CustomerLogin";
import BackButton from "./BackButton";

const Cart = () => {
  const location = useLocation();
  const [cartItems, setCartItems] = useState(() => {
    return (
      location.state?.cart ||
      JSON.parse(localStorage.getItem("currentCart")) ||
      []
    );
  });
  const [restaurantDetail, setRestaurantDetail] = useState("");
  const restaurant_id = location.state?.restaurant_id;
  const navigate = useNavigate();
  const [isScrolled, setIsScrolled] = useState(false);
  const [showRestaurantDetails, setShowRestaurantDetails] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const headerHeight = 100;
      setIsScrolled(window.scrollY > headerHeight);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // Desktop event handlers
  const handleRestaurantDetailsHover = () => {
    setShowRestaurantDetails(true);
  };

  const handleRestaurantDetailsLeave = () => {
    setShowRestaurantDetails(false);
  };

  // Mobile event handler
  const handleRestaurantDetailsClick = () => {
    setShowRestaurantDetails((prevState) => !prevState);
  };

  useEffect(() => {
    localStorage.setItem("currentCart", JSON.stringify(cartItems));
  }, [cartItems]);

  useEffect(() => {
    const fetchMenu = async () => {
      try {
        const response = await axios.get(
          `${config.API_BASE_URL}/customer/display-menu/${restaurant_id}`
        );
        const allItems = response.data;
        setRestaurantDetail(allItems.resturant);
      } catch (error) {
        console.log("Error fetching menu:", error);
      }
    };
    fetchMenu();
  }, [restaurant_id]);

  const removeItem = (itemId) => {
    const updatedCart = cartItems
      .map((item) => {
        if (item.menu_id === itemId) {
          if (item.quantity > 1) {
            return { ...item, quantity: item.quantity - 1 };
          }
          return null;
        }
        return item;
      })
      .filter(Boolean);
    setCartItems(updatedCart);
  };

  const addItem = (itemId) => {
    const updatedCart = cartItems.map((item) => {
      if (item.menu_id === itemId) {
        return { ...item, quantity: item.quantity + 1 };
      }
      return item;
    });
    setCartItems(updatedCart);
  };

  const calculateTotal = () => {
    return cartItems.reduce(
      (total, item) => total + item.price * item.quantity,
      0
    );
  };

  const handleCheckout = () => {
    navigate("/customer_details", {
      state: { cart: cartItems, restaurant_id },
    });
  };

  const [isLoginVisible, setLoginVisible] = useState(false);
  const [userData, setUserData] = useState(() => {
    const storedData = localStorage.getItem("customerData");
    return storedData && storedData !== "undefined"
      ? JSON.parse(storedData)
      : null;
  });
  const [showDropdown, setShowDropdown] = useState(false);
  const showLoginButtonPaths = ["/menu-display", "/cart", "/customer_details"];

  const handleLoginClick = () => setLoginVisible(true);
  const handleCloseLogin = () => setLoginVisible(false);
  const handleLoginSuccess = (data) => {
    setUserData(data);
    setLoginVisible(false);
  };

  return (
    <div className="max-w-7xl mx-auto p-4 md:mt-5">
      <BackButton />
      <>
        <div className="max-w-7xl mx-auto px-4 mt-18 md:mt-15">
          <div className="max-w-6xl mx-auto relative">
            <div
              className={`${
                isScrolled ? "fixed top-0" : "relative"
              } left-0 right-0 z-40 bg-white/95 backdrop-blur-sm shadow-lg transition-all duration-300`}
              onMouseEnter={handleRestaurantDetailsHover}
              onMouseLeave={handleRestaurantDetailsLeave}
              onClick={handleRestaurantDetailsClick} // Add click handler for mobile
            >
              <div className="max-w-7xl mx-auto p-2">
                <div className="flex flex-col gap-2">
                  {/* Top row with restaurant name and login */}
                  <div className="flex justify-between items-center">
                    <h1 className="text-xl font-bold truncate max-w-[60%]">
                      {restaurantDetail.name}
                    </h1>

                    {showLoginButtonPaths.includes(location.pathname) &&
                      (userData ? (
                        <div className="relative">
                          <button
                            onClick={() => setShowDropdown(!showDropdown)}
                            className="flex items-center gap-1 text-sm bg-white px-2 py-1 rounded-md hover:bg-gray-50"
                          >
                            <span className="truncate max-w-[100px]">
                              {userData.customer_name}
                            </span>
                            <svg
                              className="w-3 h-3 flex-shrink-0"
                              fill="none"
                              stroke="currentColor"
                              viewBox="0 0 24 24"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M19 9l-7 7-7-7"
                              />
                            </svg>
                          </button>
                          {showDropdown && (
                            <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1 z-50">
                              <button
                                onClick={() => {
                                  navigate("/customer_orders", {
                                    state: { customerId: userData.customer_id },
                                  });
                                  setShowDropdown(false);
                                }}
                                className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                              >
                                Order Status
                              </button>
                              <button
                                onClick={() => {
                                  localStorage.removeItem("customerData");
                                  setUserData(null);
                                  setShowDropdown(false);
                                }}
                                className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                              >
                                Logout
                              </button>
                            </div>
                          )}
                        </div>
                      ) : (
                        <button
                          onClick={handleLoginClick}
                          className="text-sm px-3 py-1 bg-[#abbfff] text-white rounded-md hover:bg-[#4e7ab4] flex-shrink-0"
                        >
                          Login
                        </button>
                      ))}
                  </div>

                  {/* Bottom row with address details */}
                  <div
                    className={`text-sm text-gray-600 overflow-x-auto ${
                      showRestaurantDetails ? "block" : "hidden"
                    }`}
                  >
                    <div className="flex items-center space-x-2 whitespace-nowrap">
                      <span className="truncate">
                        {restaurantDetail.address}
                      </span>
                      <span>•</span>
                      <span className="truncate">{restaurantDetail.city}</span>
                      <span>•</span>
                      <span className="truncate">{restaurantDetail.state}</span>
                      {restaurantDetail.phone && (
                        <>
                          <span>•</span>
                          <span>
                            {restaurantDetail.phone.replace(
                              /(\d{3})(\d{3})(\d{4})/,
                              "$1-$2-$3"
                            )}
                          </span>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-6 sm:mt-6">
          {" "}
          {/* Changed from mt-24 */}
          <h2 className="text-xl font-semibold text-center text-gray-800 mb-6">
            Your Cart
          </h2>
          {cartItems.length > 0 ? (
            cartItems.map((item) => (
              <div
                key={item.menu_id}
                className="flex items-center justify-between bg-white p-4 rounded-lg shadow-md hover:shadow-lg transition-shadow"
              >
                <div className="w-24 h-24 flex-shrink-0">
                  <img
                    src={item.image}
                    alt={item.item_name}
                    className="w-full h-full object-cover rounded-lg"
                  />
                </div>

                <div className="flex-grow text-center mx-4">
                  <h3 className="text-lg font-semibold text-gray-800">
                    {item.item_name}
                  </h3>
                  <p className="text-gray-600">Price: ${item.price}</p>
                  <p className="text-gray-800 font-medium">
                    Total: ${(item.price * item.quantity).toFixed(2)}
                  </p>
                </div>

                <div className="flex items-center space-x-4">
                  <button
                    onClick={() => removeItem(item.menu_id)}
                    className="w-8 h-8 rounded-full bg-gray-200 hover:bg-gray-300 flex items-center justify-center text-gray-800 font-bold transition"
                  >
                    -
                  </button>
                  <span className="text-lg font-semibold">{item.quantity}</span>
                  <button
                    onClick={() => addItem(item.menu_id)}
                    className="w-8 h-8 rounded-full bg-gray-200 hover:bg-gray-300 flex items-center justify-center text-gray-800 font-bold transition"
                  >
                    +
                  </button>
                </div>
              </div>
            ))
          ) : (
            <p className="text-center text-gray-600">No items in cart</p>
          )}
        </div>

        <div className="fixed bottom-0 left-0 right-0 bg-white border-t shadow-lg p-2 sm:p-4">
          <div className="max-w-3xl mx-auto">
            <div className="flex justify-between items-center">
              <span className="text-base sm:text-xl font-bold text-gray-800">
                Total: ${calculateTotal().toFixed(2)}
              </span>
              <button
                onClick={handleCheckout}
                className="px-4 sm:px-8 py-2 sm:py-3 bg-[#2563eb] text-white rounded-lg hover:bg-[#4e7ab4] transition-colors font-semibold text-base sm:text-lg"
              >
                Checkout
              </button>
            </div>
          </div>
        </div>

        {isLoginVisible && (
          <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
            <div className="bg-white rounded-lg p-6 max-w-md w-full mx-4">
              <CustomerLogin
                onClose={handleCloseLogin}
                onLoginSuccess={handleLoginSuccess}
              />
            </div>
          </div>
        )}
      </>
    </div>
  );
};

export default Cart;
