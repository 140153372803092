
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useJsApiLoader, Autocomplete } from '@react-google-maps/api';
import config from "../config";
import NotificationCart from './NotificationCart';

const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
const MAP_LIBRARIES = ['places'];

const CreateRestaurant = () => {
  const [formData, setFormData] = useState({
    restaurant_name: '',
    address: '',
    email: '',
    phone: '',
    restaurant_type: '',
    number_of_tables: 0,
    opening_hours: {
      monday: { open: '', close: '', closing_day: "", pickup: false },
      tuesday: { open: '', close: '', closing_day: "", pickup: false },
      wednesday: { open: '', close: '', closing_day: "", pickup: false },
      thursday: { open: '', close: '', closing_day: "", pickup: false },
      friday: { open: '', close: '', closing_day: "", pickup: false },
      saturday: { open: '', close: '', closing_day: "", pickup: false },
      sunday: { open: '', close: '', closing_day: "", pickup: false },
    },
    city: '',
    country: '',
    state: '',
    zip_code: '',
    user_id: sessionStorage.getItem('user_id'),
    latitude: null,
    longitude: null,
  });

  const [stateOptions, setStateOptions] = useState([]);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationStatus, setNotificationStatus] = useState("");
  const [isNotification, setIsNotification] = useState(false);
  const [autocomplete, setAutocomplete] = useState(null);

  const navigate = useNavigate();
  
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_API_KEY,
    libraries: MAP_LIBRARIES,
  });

  const handlePlaceSelect = () => {
    if (autocomplete) {
      const place = autocomplete.getPlace();
      if (place) {
        const { formatted_address, geometry, address_components } = place;
        const addressDetails = {
          address: formatted_address || "",
          latitude: geometry?.location?.lat(),
          longitude: geometry?.location?.lng(),
          city: "",
          state: "",
          country: "",
          zip_code: "",
        };
  
        address_components.forEach((component) => {
          const types = component.types;
          if (types.includes("locality")) addressDetails.city = component.long_name;
          if (types.includes("administrative_area_level_1")) addressDetails.state = component.long_name;
          if (types.includes("country")) addressDetails.country = component.long_name;
          if (types.includes("postal_code")) addressDetails.zip_code = component.long_name;
        });
  
        setFormData(prevData => ({
          ...prevData,
          ...addressDetails,
        }));
      }
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (name.startsWith('opening_hours.')) {
      const [_, day, field] = name.split(".");
      setFormData((prev) => {
        const updatedHours = { ...prev.opening_hours[day], [field]: type === "checkbox" ? checked : value };
        if (field === "close") {
          const closingTime = value;
          const [hours, minutes] = closingTime.split(":");
          const isAM = parseInt(hours, 10) < 6;
          if (isAM) {
            const days = Object.keys(prev.opening_hours);
            const currentDayIndex = days.indexOf(day);
            const nextDay = days[(currentDayIndex + 1) % days.length];
            updatedHours.closing_day = nextDay;
          } else {
            updatedHours.closing_day = day;
          }
        }

        return {
          ...prev,
          opening_hours: {
            ...prev.opening_hours,
            [day]: updatedHours,
          },
        };
      });
    } else {
      setFormData(prevData => ({
        ...prevData,
        [name]: type === 'checkbox' ? checked : value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${config.API_BASE_URL}/restaurant/create-restaurant`, formData);
      if (response.status === 200) {
        setNotificationMessage(response.data.message);
        setNotificationStatus("success");
        setIsNotification(true);
        sessionStorage.setItem('restaurant_id', response.data.restaurant_id);
        setTimeout(() => {
          setIsNotification(false);
          navigate('/dashboard');
        }, 9000);
      }
    } catch (error) {
      console.error('Error creating restaurant:', error);
      setNotificationMessage("Error creating restaurant");
      setNotificationStatus("error");
      setIsNotification(true);
      setTimeout(() => {
        setIsNotification(false);
      }, 9000);
    }
  };

  return (
    <div className="max-w-3xl mx-auto p-6 bg-white rounded-xl shadow-lg">
      <h2 className="text-2xl font-bold text-gray-800 text-center mb-8">Create Restaurant</h2>

      {isNotification && (
        <NotificationCart
          message={notificationMessage}
          status={notificationStatus}
        />
      )}

      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {/* Basic Information */}
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1 text-left">Restaurant Name</label>
              <input
                type="text"
                name="restaurant_name"
                value={formData.restaurant_name}
                onChange={handleChange}
                required
                className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1 text-left">Email</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1 text-left">Phone</label>
              <input
                type="tel"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                pattern="[0-9]{10}"
                maxLength="10"
                required
                className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              />
            </div>
          </div>

          {/* Address Information */}
          <div className="space-y-4">
              <label className="block text-sm font-medium text-gray-700 mb-1 text-left">Address</label>
              {isLoaded && (
                <Autocomplete
                  onLoad={setAutocomplete}
                  onPlaceChanged={handlePlaceSelect}
                >
                  <input
                    type="text"
                    placeholder="Search Address"
                    className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  />
                </Autocomplete>
              )}
          <div>
              <label className="block text-sm font-medium text-gray-700 mb-1 text-left">City</label>
              <input
                type="text"
                name="city"
                value={formData.city}
                onChange={handleChange}
                required
                className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1 text-left" >Country</label>
              <input
                type="text"
                name="country"
                value={formData.country}
                onChange={handleChange}
                required
                className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1 text-left">State</label>
              <input
                type="text"
                name="state"
                value={formData.state}
                onChange={handleChange}
                required
                className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1 text-left">Zip Code</label>
              <input
                type="text"
                name="zip_code"
                value={formData.zip_code}
                onChange={handleChange}
                required
                className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              />
            </div>
          </div>
        </div>

        {/* Restaurant Details */}
        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1 text-left">Restaurant Type</label>
            <input
              type="text"
              name="restaurant_type"
              value={formData.restaurant_type}
              onChange={handleChange}
              required
              className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1 text-left">Number of Tables</label>
            <input
              type="number"
              name="number_of_tables"
              value={formData.number_of_tables}
              onChange={handleChange}
              required
              className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            />
          </div>
        </div>

        {/* Opening Hours */}
        <div className="space-y-4">
          <h3 className="text-lg font-semibold text-gray-800 text-left">Opening Hours</h3>
          {Object.entries(formData.opening_hours).map(([day, hours]) => (
            <div key={day} className="flex flex-wrap items-center gap-4 p-4 bg-gray-50 rounded-lg">
              <span className="font-medium text-gray-700 w-24">
                {day.charAt(0).toUpperCase() + day.slice(1)}:
              </span>
              <input
                type="time"
                name={`opening_hours.${day}.open`}
                value={hours.open}
                onChange={handleChange}
                className="p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              />
              <input
                type="time"
                name={`opening_hours.${day}.close`}
                value={hours.close}
                onChange={handleChange}
                className="p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              />
              <label className="flex items-center gap-2">
                <input
                  type="checkbox"
                  name={`opening_hours.${day}.pickup`}
                  checked={hours.pickup}
                  onChange={handleChange}
                  className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                />
                <span className="text-sm text-gray-600">Pickup Available</span>
              </label>
            </div>
          ))}
        </div>

        <button
          type="submit"
          className="w-full py-3 px-4 bg-[#abbfff] text-white font-semibold rounded-lg shadow-md hover:bg-[#4e7ab4] focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-colors"
        >
          Create Restaurant
        </button>
      </form>
    </div>
  );
};

export default CreateRestaurant;