import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import config from "../config";

function KitchenManagement() {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const presentTime = new Date();
  const [currentTime, setCurrentTime] = useState(
    new Date(
      Date.UTC(
        presentTime.getUTCFullYear(),
        presentTime.getUTCMonth(),
        presentTime.getUTCDate(),
        presentTime.getUTCHours(),
        presentTime.getUTCMinutes(),
        presentTime.getUTCSeconds()
      )
    )
  ); // State for current time
  const restaurantId = sessionStorage.getItem("restaurant_id");

  // Fetch orders when the component mounts
  const fetchKitchenOrders = useCallback(async () => {
    try {
      const response = await axios.get(
        `${config.API_BASE_URL}/kitchen/restaurant/${restaurantId}/orders`
      );
      setOrders(response.data);
    } catch (error) {
      setError("Failed to load orders. Please try again later.");
    } finally {
      setLoading(false);
    }
  }, [restaurantId]);

  useEffect(() => {
    fetchKitchenOrders();
  }, [fetchKitchenOrders]);
  
  // Update current time in GMT every second
  useEffect(() => {
    const timerInterval = setInterval(() => {
      const now = new Date();
      const gmtTime = new Date(
        Date.UTC(
          now.getUTCFullYear(),
          now.getUTCMonth(),
          now.getUTCDate(),
          now.getUTCHours(),
          now.getUTCMinutes(),
          now.getUTCSeconds()
        )
      ).getTime();

      setCurrentTime(gmtTime); // Update current time state in GMT
    }, 1000);

    return () => clearInterval(timerInterval); // Cleanup on unmount
  }, []);

  // Helper function to format time difference
  const formatTimeDifference = (updatedAt) => {
    const orderTime = new Date(updatedAt).getTime(); // Assumes updatedAt is in GMT/UTC

    const diffInSeconds = Math.floor((currentTime - orderTime) / 1000);

    const hours = Math.floor(diffInSeconds / 3600);
    const minutes = Math.floor((diffInSeconds % 3600) / 60);
    const seconds = diffInSeconds % 60;

    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  };

  const handleUpdateOrderStatus = async (orderId, status) => {
    try {
      const response = await axios.post(
        `${config.API_BASE_URL}/kitchen/restaurant/${restaurantId}/update-order-status/${orderId}`,
        { status }
      );
      if (response.status === 200) {
        setOrders(
          orders.map((order) =>
            order.order_id === orderId ? { ...order, status } : order
          )
        );
        fetchKitchenOrders();
      }
    } catch (error) {
      alert(
        `Failed to update order status to ${status}. Please try again later.`
      );
    }
  };

  return (
    <div className="max-w-6xl mx-auto p-6">
      <h2 className="text-3xl font-bold text-gray-800 text-center mb-8">Kitchen Management</h2>

      {loading ? (
        <div className="flex justify-center items-center py-8">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
        </div>
      ) : error ? (
        <div className="bg-red-50 text-red-600 p-4 rounded-lg text-center">{error}</div>
      ) : orders.length === 0 ? (
        <p className="text-gray-600 text-center text-lg">No Orders Present</p>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {orders.map((order) => (
            <div key={order.order_id} className="bg-white rounded-xl shadow-lg overflow-hidden transform transition-all hover:scale-105">
              {/* Order Header */}
              <div className="bg-gray-50 p-4 border-b border-gray-200">
                <div className="flex justify-between items-center">
                  <h3 className="text-lg font-semibold text-gray-800">
                    Order #{order.order_id}
                  </h3>
                  <span className="text-gray-600">
                    {order.table_number ? `Table ${order.table_number}` : 'PickUp Order'}
                  </span>
                </div>
              </div>

              {/* Order Details */}
              <div className="p-4 space-y-4">
                <div className="space-y-2">
                  <div className="flex justify-between text-sm">
                    <span className="text-gray-600">Customer:</span>
                    <span className="font-medium text-gray-800">{order.customer_name}</span>
                  </div>
                  <div className="flex justify-between text-sm">
                    <span className="text-gray-600">Total:</span>
                    <span className="font-medium text-gray-800">${order.total_amount.toFixed(2)}</span>
                  </div>
                  <div className="flex justify-between text-sm">
                    <span className="text-gray-600">Status:</span>
                    <span className={`px-2 py-1 rounded-full text-xs font-medium
                      ${order.status === 'Completed' ? 'bg-green-100 text-green-800' : 
                        order.status === 'Ready' ? 'bg-yellow-100 text-yellow-800' :
                        'bg-blue-100 text-blue-800'}`}>
                      {order.status}
                    </span>
                  </div>
                  <div className="flex justify-between text-sm">
                    <span className="text-gray-600">Time Elapsed:</span>
                    <span className="font-medium text-gray-800">{formatTimeDifference(order.updated_at)}</span>
                  </div>
                </div>

                {/* Order Items */}
                <div className="border-t border-gray-200 pt-4">
                  <h4 className="text-sm font-medium text-gray-600 mb-2">Order Items:</h4>
                  <ul className="space-y-2">
                    {order.items.map((item) => (
                      <li key={item.menu_item_id} className="flex justify-between text-sm">
                        <span className="text-gray-800">{item.product_name}</span>
                        <div className="flex items-center gap-2">
                          <span className="text-gray-600">x{item.quantity}</span>
                          <span className="text-gray-800">${item.price.toFixed(2)}</span>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>

                {/* Action Button */}
                <div className="mt-4">
                  {order.status !== "Completed" && order.status !== "Cancelled" && (
                    <button
                      onClick={() => handleUpdateOrderStatus(order.order_id, "Ready")}
                      className="w-full py-2 bg-[#abbfff] text-white rounded-lg hover:bg-[#4e7ab4] transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                    >
                      Mark as Ready
                    </button>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default KitchenManagement;