import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import config from "../config";
import bg4 from "../assets/bg4.jpg";
import CartIcon from "./Carticon";

const Home = () => {
  const [searchResults, setSearchResults] = useState([]);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [showBackToTop, setShowBackToTop] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isMenuOpen && !event.target.closest(".nav-container")) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [isMenuOpen]);

  useEffect(() => {
    const handleScroll = () => {
      setShowBackToTop(window.scrollY > 300);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const navigate = useNavigate();

  const handleUpdateCart = (menuId, quantity) => {
    quantity <= 0
      ? setCart((prevCart) =>
          prevCart.filter((item) => item.menu_id !== menuId)
        )
      : setCart((prevCart) =>
          prevCart.map((item) =>
            item.menu_id === menuId ? { ...item, quantity } : item
          )
        );
  };

  const handleAddToCart = (item) => {
    setCart((prevCart) => {
      const existingItem = prevCart.find((i) => i.menu_id === item.menu_id);
      return existingItem
        ? prevCart.map((i) =>
            i.menu_id === item.menu_id ? { ...i, quantity: i.quantity + 1 } : i
          )
        : [...prevCart, { ...item, quantity: 1 }];
    });
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
    closeMenu();
  };

  const handleBackToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const getCurrentDay = () => {
    const days = [
      "sunday",
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
      "saturday",
    ];
    return days[new Date().getDay()];
  };

  const [cart, setCart] = useState(() => {
    const savedCart = localStorage.getItem("currentCart");
    return savedCart ? JSON.parse(savedCart) : [];
  });
  const [showCartModal, setShowCartModal] = useState(false);
  const [touching, setTouching] = useState(false);
  const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });
  const [cartPosition, setCartPosition] = useState(() => {
    const saved = localStorage.getItem("cartPosition");
    return saved ? JSON.parse(saved) : { x: window.innerWidth - 100, y: 20 };
  });
  const [touchPosition, setTouchPosition] = useState({
    x: window.innerWidth - 80,
    y: window.innerHeight / 2,
  });

  const handleDragStart = (e) => {
    const rect = e.target.getBoundingClientRect();
    const offsetX = e.clientX - rect.left;
    const offsetY = e.clientY - rect.top;
    e.dataTransfer.setData("text/plain", `${offsetX},${offsetY}`);
  };

  const handleDrag = (e) => {
    if (e.clientX && e.clientY) {
      const [offsetX, offsetY] = e.dataTransfer
        .getData("text/plain")
        .split(",");
      const x = Math.min(
        Math.max(0, e.clientX - offsetX),
        window.innerWidth - 100
      );
      const y = Math.min(
        Math.max(0, e.clientY - offsetY),
        window.innerHeight - 100
      );
      setCartPosition({ x, y });
      localStorage.setItem("cartPosition", JSON.stringify({ x, y }));
    }
  };

  const handleMobileTouchStart = (e) => {
    const touch = e.touches[0];
    setTouching(true);
    setDragOffset({
      x: touch.clientX - touchPosition.x,
      y: touch.clientY - touchPosition.y,
    });
  };

  const handleMobileTouchMove = (e) => {
    if (touching) {
      const touch = e.touches[0];
      const newX = Math.min(
        Math.max(0, touch.clientX - dragOffset.x),
        window.innerWidth - 80
      );
      const newY = Math.min(
        Math.max(0, touch.clientY - dragOffset.y),
        window.innerHeight - 80
      );
      setTouchPosition({ x: newX, y: newY });
    }
  };

  const handleMobileTouchEnd = () => setTouching(false);

  const calculateTotal = () =>
    cart
      .reduce((total, item) => total + item.price * item.quantity, 0)
      .toFixed(2);

  const handleCheckout = () => {
    navigate("/cart", {
      state: { cart, restaurant_id: localStorage.getItem("restaurant_id") },
    });
  };

  const handleSearchChange = async (e) => {
    const query = e.target.value;
    if (query.length > 2) {
      const response = await axios.get(
        `${config.API_BASE_URL}/restaurant/search-restaurants?query=${query}`
      );
      setSearchResults(response.data);
    } else {
      setSearchResults([]);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const headerHeight = 100;
      setIsScrolled(window.scrollY > headerHeight);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="flex flex-col min-h-screen bg-gray-100 font-poppins">
      <header
        className={`${
          isScrolled ? "fixed top-0" : "relative"
        } w-full z-50 bg-[#4e7ab4] text-white transition-all duration-300`}
      >
        <div className="flex justify-between items-center max-w-7xl mx-auto py-2 px-4">
          <div className="nav-container relative">
            <button
              className="hamburger cursor-pointer md:hidden"
              onClick={toggleMenu}
              aria-label="Toggle Menu"
            >
              <div className="w-6 h-0.5 bg-white mb-1"></div>
              <div className="w-6 h-0.5 bg-white mb-1"></div>
              <div className="w-6 h-0.5 bg-white"></div>
            </button>
            <nav
              className={`${
                isMenuOpen ? "block" : "hidden"
              } md:flex md:items-center md:justify-center absolute top-full bg-[#4e7ab4] p-4 rounded shadow-lg md:relative md:bg-transparent md:shadow-none md:p-0 w-48 md:w-auto text-center`}
            >
              <div className="flex flex-col md:flex-row md:items-center md:space-x-8 space-y-2 md:space-y-0 w-full font-bold">
                <Link
                  to="/login"
                  className="hover:text-[#abbfff] w-full md:w-auto"
                  onClick={closeMenu}
                >
                  Login
                </Link>
                <Link
                  to="/register"
                  className="hover:text-[#abbfff] w-full md:w-auto"
                  onClick={closeMenu}
                >
                  Sign Up
                </Link>
                <button
                  className="hover:text-[#abbfff] w-full md:w-auto text-center"
                  onClick={() => scrollToSection("features")}
                >
                  Features
                </button>
                <button
                  className="hover:text-[#abbfff] w-full md:w-auto text-center"
                  onClick={() => scrollToSection("pricing")}
                >
                  Pricing
                </button>
                <button
                  className="hover:text-[#abbfff] w-full md:w-auto text-center"
                  onClick={() => scrollToSection("testimonials")}
                >
                  Testimonials
                </button>
                <Link
                  to="/contact"
                  className="hover:text-[#abbfff] w-full md:w-auto"
                  onClick={closeMenu}
                >
                  Contact
                </Link>
              </div>
            </nav>
          </div>
        </div>
      </header>
      <main className="flex-1 flex flex-col items-center">
        {" "}
        <section
          id="hero"
          className="relative w-full h-96 bg-cover bg-center bg-no-repeat flex flex-col justify-center items-center text-center text-white "
          style={{
            backgroundImage: `url(${bg4})`,
          }}
        >
          <div className="absolute inset-0 bg-black opacity-50"></div>
          <div className="relative z-10">
            <h2 className="text-2xl text-white font-bold uppercase tracking-wide">
              Welcome to Restaurant Table-Server-Assist
            </h2>
            <p className="text-xl text-white mt-4 mb-4">
              Manage your restaurant operations efficiently.
            </p>
            <Link
              to="/register"
              className="mt-6 px-8 py-3 bg-[#abbfff] text-gray-800 rounded hover:bg-[#4e7ab4] hover:text-white transition duration-300"
            >
              Get Started
            </Link>
          </div>
        </section>
        <section
          id="restaurant-search"
          className="mt-10 w-full max-w-4xl text-center"
        >
          <h2 className="text-3xl font-semibold text-gray-800">
            Find a Restaurant
          </h2>
          <input
            type="text"
            placeholder="Search for restaurants... By ('name' 'city' 'zipcode')"
            onChange={handleSearchChange}
            className="mt-4 p-3 w-full border border-gray-300 rounded focus:outline-none focus:border-gray-500"
          />
          <div className="mt-4 grid gap-4">
            {searchResults.map((restaurant) => (
              <div
                key={restaurant.id}
                className="bg-white p-4 rounded shadow-lg"
              >
                <h3 className="text-xl font-semibold text-gray-800">
                  {restaurant.name}
                  <Link to={`/menu-display?id=${restaurant.id}`}>
                    <button className="ml-4 bg-[#4e7ab4] text-white px-4 py-2 rounded hover:bg-[#abbfff] transition duration-300">
                      Order Now
                    </button>
                  </Link>
                </h3>
                <div className="text-gray-700 mt-2">
                  <p>{restaurant.description}</p>
                  <p>📞 {restaurant.phone}</p>
                  <p>
                    📍 {restaurant.address}, {restaurant.city}
                  </p>
                  <details className="mt-2">
                    <summary className="text-sm text-gray-500">
                      Opening hours:{" "}
                      {restaurant.opening_hours[getCurrentDay()].open} -{" "}
                      {restaurant.opening_hours[getCurrentDay()].close}
                    </summary>
                    <div className="mt-2 text-gray-600">
                      {Object.entries(restaurant.opening_hours).map(
                        ([day, hours]) => (
                          <p key={day}>
                            {day.charAt(0).toUpperCase() + day.slice(1)}:{" "}
                            {hours.open} - {hours.close}{" "}
                            {hours.pickup && "(Pickup Available)"}
                          </p>
                        )
                      )}
                    </div>
                  </details>
                </div>
              </div>
            ))}
          </div>
        </section>
        <section id="features" className="mt-12 w-full max-w-5xl text-center">
          <h2 className="text-3xl font-semibold text-gray-800">Features</h2>
          <div className="grid md:grid-cols-3 gap-6 mt-6">
            <div className="p-4 bg-white rounded shadow-lg hover:bg-gray-100">
              <div className="text-4xl">📦</div>
              <h3 className="text-xl font-semibold mt-2">Order Management</h3>
              <ul className="mt-2 text-gray-700 space-y-1">
                <li>🕒 Real-time order tracking</li>
                <li>📲 Instant notifications</li>
                <li>📊 Order history and analytics</li>
                <li>🔔 Custom order status updates</li>
              </ul>
            </div>
            <div className="p-4 bg-white rounded shadow-lg hover:bg-gray-100">
              <div className="text-4xl">📄</div>
              <h3 className="text-xl font-semibold mt-2">Menu Management</h3>
              <ul className="mt-2 text-gray-700 space-y-1">
                <li>🖼️ Digital menu creation</li>
                <li>⚙️ Easy menu updates</li>
                <li>📑 Category organization</li>
                <li>🎉 Special offers management</li>
              </ul>
            </div>
            <div className="p-4 bg-white rounded shadow-lg hover:bg-gray-100">
              <div className="text-4xl">💬</div>
              <h3 className="text-xl font-semibold mt-2">
                Customer Experience
              </h3>
              <ul className="mt-2 text-gray-700 space-y-1">
                <li>🏆 Loyalty rewards</li>
                <li>👥 Customizable profiles</li>
                <li>📥 Feedback collection</li>
                <li>📧 Email notifications</li>
              </ul>
            </div>
          </div>
        </section>
        <section id="pricing" className="mt-12 w-full max-w-4xl text-center">
          <h2 className="text-3xl font-semibold text-gray-800">
            Choose Your Plan
          </h2>
          <div className="flex flex-wrap gap-6 mt-6 justify-center">
            <div className="bg-white p-6 rounded shadow-lg w-full md:w-1/3 text-left border border-gray-200 hover:shadow-xl transition-shadow duration-200">
              <h3 className="text-xl font-semibold text-gray-800">Starter</h3>
              <div className="text-3xl font-bold mt-4 text-gray-900">
                $50<span className="text-lg">/month</span>
              </div>
              <ul className="mt-4 space-y-2 text-gray-700">
                <li>✅ Essential features</li>
                <li>✅ Real-time ordering</li>
                <li>✅ Basic analytics</li>
                <li>✅ Email support</li>
              </ul>
              <button className="mt-6 w-full bg-[#4e7ab4] text-white px-4 py-2 rounded hover:bg-[#abbfff] transition-colors duration-300">
                Get Started
              </button>
            </div>

            <div className="bg-white p-6 rounded shadow-lg w-full md:w-1/3 text-left border border-gray-200 hover:shadow-xl transition-shadow duration-200">
              <h3 className="text-xl font-semibold text-gray-800">
                Professional
              </h3>
              <div className="text-3xl font-bold mt-4 text-gray-900">
                $100<span className="text-lg">/month</span>
              </div>
              <ul className="mt-4 space-y-2 text-gray-700">
                <li>✅ All starter features</li>
                <li>✅ Advanced analytics</li>
                <li>✅ 24/7 support</li>
                <li>✅ Customizable menus</li>
              </ul>
              <button className="mt-6 w-full bg-[#4e7ab4] text-white px-4 py-2 rounded hover:bg-[#abbfff] transition-colors duration-300">
                Get Started
              </button>
            </div>

            <div className="bg-white p-6 rounded shadow-lg w-full md:w-1/3 text-left border border-gray-200 hover:shadow-xl transition-shadow duration-200">
              <h3 className="text-xl font-semibold text-gray-800">
                Enterprise
              </h3>
              <div className="text-3xl font-bold mt-4 text-gray-900">
                $200<span className="text-lg">/month</span>
              </div>
              <ul className="mt-4 space-y-2 text-gray-700">
                <li>✅ All Professional features</li>
                <li>✅ Priority support</li>
                <li>✅ Custom integrations</li>
                <li>✅ Dedicated account manager</li>
              </ul>
              <button className="mt-6 w-full bg-[#4e7ab4] text-white px-4 py-2 rounded hover:bg-[#abbfff] transition-colors duration-300">
                Get Started
              </button>
            </div>
          </div>
        </section>
        <section
          id="testimonials"
          className="mt-12 w-full max-w-6xl mx-auto text-center px-4"
        >
          <h2 className="text-4xl font-semibold text-gray-800 mb-8">
            What Our Clients Say
          </h2>
          <div className="flex flex-wrap justify-center gap-8">
            <div className="w-full sm:w-1/2 md:w-1/3 p-4">
              <div className="bg-gradient-to-r from-[#4e7ab4] to-[#7db1e5] p-6 rounded-lg shadow-xl">
                <div className="flex justify-center mb-4">
                  <img
                    src="https://avataaars.io/?avatarStyle=Circle&topType=ShortHairShortFlat&accessoriesType=Blank&hairColor=BrownDark&facialHairType=Blank&clotheType=BlazerShirt&eyeType=Default&eyebrowType=Default&mouthType=Smile&skinColor=Light"
                    alt="John Doe"
                    className="w-16 h-16 rounded-full object-cover border-4 border-white"
                  />
                </div>
                <p className="text-white text-lg italic mb-4">
                  "This system has completely transformed how we manage orders
                  and interact with customers. Highly recommended!"
                </p>
                <p className="text-white font-semibold">John Doe</p>
                <p className="text-gray-200">Restaurant Owner</p>
              </div>
            </div>

            <div className="w-full sm:w-1/2 md:w-1/3 p-4">
              <div className="bg-gradient-to-r from-[#4e7ab4] to-[#7db1e5] p-6 rounded-lg shadow-xl">
                <div className="flex justify-center mb-4">
                  <img
                    src="https://avataaars.io/?avatarStyle=Circle&topType=LongHairStraight&accessoriesType=Blank&hairColor=Black&facialHairType=Blank&clotheType=BlazerSweater&eyeType=Default&eyebrowType=Default&mouthType=Smile&skinColor=Light"
                    alt="Jane Smith"
                    className="w-16 h-16 rounded-full object-cover border-4 border-white"
                  />
                </div>
                <p className="text-white text-lg italic mb-4">
                  "A game changer for our restaurant! The streamlined process
                  makes everything faster and more efficient."
                </p>
                <p className="text-white font-semibold">Jane Smith</p>
                <p className="text-gray-200">Restaurant Manager</p>
              </div>
            </div>

            <div className="w-full sm:w-1/2 md:w-1/3 p-4">
              <div className="bg-gradient-to-r from-[#4e7ab4] to-[#7db1e5] p-6 rounded-lg shadow-xl">
                <div className="flex justify-center mb-4">
                  <img
                    src="https://avataaars.io/?avatarStyle=Circle&topType=ShortHairDreads01&accessoriesType=Round&hairColor=Black&facialHairType=BeardMedium&facialHairColor=Black&clotheType=Hoodie&clotheColor=Gray&eyeType=Happy&eyebrowType=Default&mouthType=Smile&skinColor=Light"
                    alt="Mark Johnson"
                    className="w-16 h-16 rounded-full object-cover border-4 border-white"
                  />
                </div>
                <p className="text-white text-lg italic mb-4">
                  "The user interface is so intuitive, and the system works
                  flawlessly. It has significantly reduced the stress of
                  managing orders!"
                </p>
                <p className="text-white font-semibold">Mark Johnson</p>
                <p className="text-gray-200">Operations Manager</p>
              </div>
            </div>
          </div>
        </section>
        {showBackToTop && (
          <button
            onClick={handleBackToTop}
            className="fixed bottom-8 right-8 bg-[#4e7ab4] text-white p-4 rounded-full shadow-lg hover:bg-[#abbfff] transition-colors duration-300 z-50 flex items-center justify-center group"
            aria-label="Back to top"
          >
            <svg
              className="w-6 h-6 group-hover:transform group-hover:-translate-y-1 transition-transform duration-300"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M5 10l7-7m0 0l7 7m-7-7v18"
              />
            </svg>
          </button>
        )}
      </main>
      {cart.length > 0 && (
        <CartIcon
          cart={cart}
          showCartModal={showCartModal}
          setShowCartModal={setShowCartModal}
          touching={touching}
          touchPosition={touchPosition}
          handleMobileTouchStart={handleMobileTouchStart}
          handleMobileTouchMove={handleMobileTouchMove}
          handleMobileTouchEnd={handleMobileTouchEnd}
          cartPosition={cartPosition}
          handleDragStart={handleDragStart}
          handleDrag={handleDrag}
          handleUpdateCart={handleUpdateCart}
          handleAddToCart={handleAddToCart}
          calculateTotal={calculateTotal}
          handleCheckout={handleCheckout}
        />
      )}
    </div>
  );
};
export default Home;
